import { FormControl, FormHelperText, TextField } from "@mui/material";
import { FC, HTMLInputTypeAttribute } from "react";
import { Control, Controller } from "react-hook-form";

interface Props {
    label: string;
    marginTop?: number;
    marginBottom?: number;
    marginRight?: number;
    width?: string | number;
    maxWidth?: number;
    nameField?: string;
    control?: Control<any, any>;
    type?: HTMLInputTypeAttribute;
}

const Input: FC<Props> = ({
    label,
    marginTop,
    marginBottom = 30,
    marginRight,
    width = "100%",
    maxWidth,
    nameField,
    control,
    type
}) => {
    return (
        <Controller
            render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
            }) => (
                <FormControl
                    variant="standard"
                    style={
                        {
                            maxWidth,
                            width,
                            marginRight,
                            marginTop,
                            marginBottom,
                        }
                    }
                >
                    <TextField
                        type={type}
                        error={invalid}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        inputRef={ref}
                        label={label}
                        variant="outlined"
                        style={{
                            width: "100%",
                        }}
                    />

                    <FormHelperText
                        error
                        style={{
                            minHeight: 20
                        }}
                    >
                        {error?.message}
                    </FormHelperText>
                </FormControl>
            )}
            name={nameField || ""}
            control={control}
        />
    );
}

export default Input;