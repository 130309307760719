import { AlertColor } from "@mui/material";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type Feedback = {
    message: string | null;
    type: AlertColor | null;
    autoHideDuration: number;
};

interface AppState {
    feedback: Feedback;
}

const initialState: AppState = {
    feedback: {
        message: null,
        type: null,
        autoHideDuration: 3000
    },
};

export const appSlice = createSlice({
    initialState,
    name: 'appSlice',
    reducers: {
        setFeedback: (state, action: PayloadAction<Pick<Feedback, "message" | "type">>) => {
            const { message, type } = action.payload;

            state.feedback.message = message;
            state.feedback.type = type;
        },
    },
});

export default appSlice.reducer;

export const { setFeedback } = appSlice.actions;
