import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BaseUrl, Langs, ResponseError, ResponsesStatus } from './constans';
import prepareHeaders from '../../utl/prepareHeaders';

export interface Tour {
    id: number;
    name: Langs;
    description: Langs;
    miniature: string;
    reviewLink: string;
    mapTemplate: number;
    guidesIds: number[];
    usefulContactsIds: number[];
}

export type TourId = Pick<Tour, "id">;

export type CreateTour = Omit<Tour, "id">;

export const TourTag = "Tour";

export const toursApi = createApi({
    reducerPath: 'toursApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BaseUrl}/tours/`,
        prepareHeaders,
        credentials: 'include',
    }),
    tagTypes: [TourTag],
    endpoints: (build) => ({
        getTours: build.query<Tour[], void>({
            query: () => "",
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ id }) => ({ type: TourTag, id } as const)),
                        { type: TourTag, id: 'LIST' },
                    ]
                    :
                    [{ type: TourTag, id: 'LIST' }],
            transformResponse: (response: { tours: Tour[] }) => response.tours,
        }),
        getTour: build.query<Tour, number>({
            query: (id) => `${id}`,
            providesTags: (result, error, id) => [{ type: TourTag, id }],
            transformResponse: (response: { tour: Tour }) => response.tour,
        }),
        createTour: build.mutation<TourId, CreateTour>({
            query: (body) => {
                return {
                    url: "",
                    method: "POST",
                    body,
                }
            },
            invalidatesTags: [{ type: TourTag, id: 'LIST' }],
            transformErrorResponse: (response: ResponseError) => response,
        }),
        updateTour: build.mutation<Tour, Tour>({
            query: (body) => {
                return {
                    url: `${body.id}`,
                    method: "PUT",
                    body,
                }
            },
            invalidatesTags: (result, error, { id }) =>
                result
                    ? [
                        { type: TourTag, id },
                        { type: TourTag, id: 'LIST' },
                    ]
                    :
                    [{ type: TourTag, id: 'LIST' }],
            transformErrorResponse: (response: ResponseError) => response,
        }),
        deleteTour: build.mutation<ResponsesStatus, number>({
            query: (id) => {
                return {
                    url: `${id}`,
                    method: "DELETE",
                }
            },
            invalidatesTags: [{ type: TourTag, id: 'LIST' }],
            transformErrorResponse: (response: ResponseError) => response,
        })
    }),
});

export const {
    useGetToursQuery,
    useGetTourQuery,
    useCreateTourMutation,
    useUpdateTourMutation,
    useDeleteTourMutation
} = toursApi;