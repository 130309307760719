import { PropsAdminBreadcrumbs } from "../../components/navigation/AdminBreadcrumbs/AdminBreadcrumbs";
import { RootPath } from "../../constans/app";
import { useGetToursQuery } from "../../store/services/toursApi";
import { TableToursRow } from "./models/TableToursRow";
import Tours from "./Tours";

const ToursContainer = () => {
    const { data, isLoading, isError } = useGetToursQuery();

    const rows: TableToursRow[] = data ? data.map((tour) => ({
        id: tour.id,
        name: tour.name.ua,
        miniature: tour.miniature,
    })) : [];

    const breadcrumbs: PropsAdminBreadcrumbs = {
        home: {
            link: `/${RootPath}`,
            name: "Home"
        },
        target: "Tours"
    }

    return (
        <Tours
            rows={rows}
            breadcrumbs={breadcrumbs}
        />
    );
}

export default ToursContainer;