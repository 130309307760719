import { Avatar, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { FC, useEffect, useState } from "react";
import { SelectItem } from "./models/SelectItem";
import { FormState, UseFormGetFieldState, UseFormSetValue } from "react-hook-form";
import getIconLink from "../../../utl/getIconLink";

interface Props {
    label: string;
    selects: SelectItem[];
    nameField: string;
    marginBottom?: number;
    setValue: UseFormSetValue<any>;
    getFieldState: UseFormGetFieldState<any>;
    formState: FormState<any>;
    value: string;
    withIcon?: boolean;
}

const BasicSelect: FC<Props> = ({
    label,
    selects,
    marginBottom,
    nameField,
    setValue,
    value,
    getFieldState,
    formState,
    withIcon = false,
}) => {
    const { invalid } = getFieldState(nameField, formState);

    const [selectValue, setSelectValue] = useState('');

    useEffect(() => {
        setSelectValue(value);
    }, [value]);

    const handleChange = (event: SelectChangeEvent) => {
        const select = event.target.value as string

        setSelectValue(select);

        setValue(nameField, select, { shouldValidate: true });
    };

    const selectsList = selects.map((select) => (
        <MenuItem key={select.key} value={select.key}>
            {select.name}
        </MenuItem>
    ));

    const labelId = `${nameField}-select-label`;

    return (
        <FormControl component="div" style={{ width: 415, marginBottom }}>
            <InputLabel id={labelId}>
                {label}
            </InputLabel>

            <Select
                error={invalid}
                labelId={labelId}
                id={`${label}-select`}
                label={label}
                value={selectValue}
                onChange={handleChange}
                style={{ marginBottom: 15 }}
            >
                {selectsList}
            </Select>

            {withIcon &&
                <Avatar
                    alt="miniature"
                    src={getIconLink(selectValue)}
                    variant="rounded"
                    sx={{ width: 60, height: 60 }}
                />
            }
        </FormControl>
    )
}

export default BasicSelect;