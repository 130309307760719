export const BaseUrl = "https://sharingheritage.online";
export const AccessToken = "access-token";
export const ExpiresIn = "expiresIn";
export interface Langs {
    ua: string;
    en: string;
}

export interface ResponseError {
    data: { 
        statusCode: number, 
        message: string 
    };
    status: number;
}

export interface ResponsesStatus { status: number, message: string };