import foodIcon from "../assets/svg/icon/food.svg";
import houseIcon from "../assets/svg/icon/home.svg";
import infoIcon from "../assets/svg/icon/info.svg";
import maskIcon from "../assets/svg/icon/mask-happy.svg";
import travelIcon from "../assets/svg/icon/outline-mode-of-travel.svg";
import shopIcon from "../assets/svg/icon/shop.svg";

const getIconLink = (key: string): string => {
    switch (key) {
        case "food":
            return foodIcon;
        case "house":
            return houseIcon;
        case "info":
            return infoIcon;
        case "mask":
            return maskIcon;
        case "travel":
            return travelIcon;
        case "shop":
            return shopIcon;
        default : return ""
    }
}

export default getIconLink;