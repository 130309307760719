import { useForm } from "react-hook-form";
import Login from "./Login";
import { LoginFormData } from "./models/LoginFormData";
import { yupResolver } from '@hookform/resolvers/yup';
import LoginSchema from "./schema/Login.schema";
import { useLoginMutation } from "../../store/services/authApi";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import { shallowEqual } from "react-redux";

const defaultValues: LoginFormData = {
    email: "",
    password: ""
}

interface Props {
    baseUrl?: string;
}

const LoginContainer: FC<Props> = ({
    baseUrl
}) => {
    const user = useAppSelector(state => state.userState.user, shallowEqual);

    const [login, { isError }] = useLoginMutation();
    const navigate = useNavigate();

    const { handleSubmit, control, getValues, setError, formState: { errors } } = useForm<LoginFormData>({
        defaultValues,
        resolver: yupResolver(LoginSchema),
        mode: "all",
    });

    const onSubmit = handleSubmit(data => login(getValues()));

    useEffect(() => {
        if (isError) {
            setError("email", { type: 'invalid', message: 'Невірна пошта' });
            setError("password", { type: 'invalid', message: 'Невірний пароль' });
        }
    }, [isError]);

    useEffect(() => {
        if (user) {
            navigate(`/${baseUrl}`);
        }
    }, [user]);

    return (
        <Login
            onSubmit={onSubmit}
            control={control}
        />
    );
}

export default LoginContainer;