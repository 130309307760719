import H2 from "../../typography/H2"
import H3 from "../../typography/H3"
import Miniature from "../../dataDisplay/Miniature/Miniature"
import { Control, Controller } from "react-hook-form"
import { FC } from "react";
import { FormControl, FormHelperText, TextField } from "@mui/material";

interface Props {
    nameField?: string;
    control?: Control<any, any>;
}

const InputMiniature: FC<Props> = ({
    nameField,
    control,
}) => {
    return (
        <Controller
            render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
            }) => (
                <>
                    <H2
                        name="Мініатюра"
                    />

                    <FormControl
                        variant="standard"
                        style={
                            {
                                width: "100%",
                                marginBottom: 15,
                            }
                        }
                    >
                        <TextField
                            error={invalid}
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            inputRef={ref}
                            label={"URL"}
                            variant="outlined"
                            style={{
                                width: "100%",
                            }}
                        />

                        <FormHelperText
                            error
                            style={{
                                minHeight: 20
                            }}
                        >
                            {error?.message}
                        </FormHelperText>
                    </FormControl>

                    <H3
                        name="Прев’ю"
                    />

                    <Miniature
                        image={value}
                    />
                </>
            )}
            name={nameField || ""}
            control={control}
        />
    )
}

export default InputMiniature;