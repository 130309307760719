import { FC, useEffect, useState } from "react";
import { PropsAdminBreadcrumbs } from "../../../components/navigation/AdminBreadcrumbs/AdminBreadcrumbs";
import { ServiceFormData } from "../models/ServiceFormData";
import Service from "./Service";
import { yupResolver } from '@hookform/resolvers/yup';
import ServiceSchema from "./schema/Service.schema";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useCreateServiceMutation, useDeleteServiceMutation, useGetServiceQuery, useUpdateServiceMutation } from "../../../store/services/servicesApi";
import { useGetCategoriesListQuery } from "../../../store/services/categoriesApi";
import useActions from "../../../hooks/useActions";
import useNavigateTo from "../../../hooks/useNavigateTo";
import useAction from "../../../hooks/useAction";
import { ChipValue } from "../../../components/inputs/MultipleSelectChip/MultipleSelectChip";
import { RootPath } from "../../../constans/app";

const defaultValues: ServiceFormData = {
    nameUA: "",
    descriptionUA: "",
    nameEN: "",
    descriptionEN: "",
    miniature: "",
    gps: "",
    categories: ""
}

interface Props {
    isNewService?: boolean;
}

const ServiceContainer: FC<Props> = ({
    isNewService
}) => {
    let { id } = useParams();

    const { data, isLoading, isError } = useGetServiceQuery(Number(id));

    const service = data;

    const categoriesData = useGetCategoriesListQuery();

    const categoriesList = categoriesData.data?.map(category => ({
        id: category.key,
        name: category.name,
    })) || [];

    const [createService, createActions] = useCreateServiceMutation();
    const [updateService, updateActions] = useUpdateServiceMutation();
    const [deleteService, deleteActions] = useDeleteServiceMutation();

    //@ts-ignore
    useActions([{ error: createActions.error, isSuccess: createActions.isSuccess }, { error: updateActions.error, isSuccess: updateActions.isSuccess }, { error: deleteActions.error, isSuccess: deleteActions.isSuccess }]);

    const targetPage = id ? service?.name?.en || "None" : "New Service"

    const breadcrumbs: PropsAdminBreadcrumbs = {
        home: {
            link: `/${RootPath}services`,
            name: "Services"
        },
        target: targetPage
    }

    const { handleSubmit, reset, control, getValues, getFieldState, setValue, formState } = useForm<ServiceFormData>({
        defaultValues,
        resolver: yupResolver(ServiceSchema),
        mode: "all",
    });

    const [categoriesValues, setCategoriesValues] = useState("");

    useEffect(() => {
        if (!isLoading && service) {
            setValue("nameUA", service.name.ua);
            setValue("nameEN", service.name.en);
            setValue("descriptionUA", service.description.ua);
            setValue("descriptionEN", service.description.en);
            setValue("miniature", service.miniature);
            setValue("gps", service.coordinates);

            const categoriesIds = service.categoriesIds.join(",");

            setValue("categories", categoriesIds)
            setCategoriesValues(categoriesIds);
        }
    }, [isLoading, service]);

    const navigate = useNavigateTo(`/${RootPath}services`);

    useAction(deleteActions, navigate);

    useAction(createActions, navigate);

    const onSave = handleSubmit(data => {
        const {
            nameEN,
            nameUA,
            descriptionEN,
            descriptionUA,
            miniature,
            gps,
            categories,
        } = data;

        const nameUAWithReplace = nameUA.replaceAll('"', '\\"');
        const nameENWithReplace = nameEN.replaceAll('"', '\\"');
        const descriptionUAWithReplace = descriptionUA.replaceAll('"', '\\"');
        const descriptionENWithReplace = descriptionEN.replaceAll('"', '\\"');

        const categoriesIds = categories.length > 0 ? categories.split(",").map(categoryId => parseInt(categoryId)) : [];

        if (isNewService) {
            createService({
                name: {
                    ua: nameUAWithReplace,
                    en: nameENWithReplace
                },
                description: {
                    ua: descriptionUAWithReplace,
                    en: descriptionENWithReplace
                },
                miniature,
                coordinates: gps,
                categoriesIds
            });
        } else {
            updateService({
                id: parseInt(id || "-1"),
                name: {
                    ua: nameUAWithReplace,
                    en: nameENWithReplace
                },
                description: {
                    ua: descriptionUAWithReplace,
                    en: descriptionENWithReplace
                },
                miniature,
                coordinates: gps,
                categoriesIds
            });
        }
    });

    const onDelete = handleSubmit(data => {
        if (!isNewService) {
            deleteService(parseInt(id || "-1"));
        }
    });

    return (
        <Service
            breadcrumbs={breadcrumbs}
            control={control}
            onSave={onSave}
            onDelete={onDelete}
            isNewService={isNewService}
            setValue={setValue}
            getFieldState={getFieldState}
            formState={formState}
            categoriesValues={categoriesValues}
            categoriesList={categoriesList}
        />
    )
}

export default ServiceContainer;