import { useNavigate } from "react-router-dom";
import Guides from "./Guides";
import { TableGuidesRow } from "./models/TableGuidesRow";
import { PropsAdminBreadcrumbs } from "../../components/navigation/AdminBreadcrumbs/AdminBreadcrumbs";
import { FC } from "react";
import { useDeleteGuideMutation, useGetGuidesQuery } from "../../store/services/guidesApi";
import useActions from "../../hooks/useActions";
import useNavigateTo from "../../hooks/useNavigateTo";
import { RootPath } from "../../constans/app";

const GuidesContainer: FC = () => {
    const { data, isLoading, isError } = useGetGuidesQuery();
    const [deleteGuide, deleteActions] = useDeleteGuideMutation();

    //@ts-ignore
    useActions([{ error: deleteActions.error, isSuccess: deleteActions.isSuccess }]);

    const rows: TableGuidesRow[] = data ? data?.map((guide) => ({
        id: guide.id,
        name: guide.name.ua,
        phone: guide.phone,
        action: () => { deleteGuide(guide.id) },
    })) : [];

    const handleNavigateClick = useNavigateTo('new');

    const breadcrumbs: PropsAdminBreadcrumbs = {
        home: {
            link: `/${RootPath}`,
            name: "Home"
        },
        target: "Guides"
    }

    return (
        <Guides
            rows={rows}
            handleNavigateClick={handleNavigateClick}
            breadcrumbs={breadcrumbs}
        />
    );
}

export default GuidesContainer;