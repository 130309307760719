import { BaseQueryApi } from "@reduxjs/toolkit/dist/query";
import { MaybePromise } from "@reduxjs/toolkit/dist/query/tsHelpers";
import { RootState } from "../store";

const prepareHeaders = (headers: Headers, api: Pick<BaseQueryApi, "getState" | "endpoint" | "type" | "extra" | "forced">): MaybePromise<void | Headers> | undefined => {
    const { getState, endpoint } = api;

    const user = (getState() as RootState).userState.user;

    if (user && endpoint !== 'refresh') {
        const token = user.accessToken;

        headers.set('Authorization', `Bearer ${token}`)
    }

    return headers;
}

export default prepareHeaders;