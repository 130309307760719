import { CardMedia } from "@mui/material";
import { FC } from "react";
import miniature from "../../../assets/img/miniature.png";

interface Props {
    width?: number;
    height?: number;
    image: string;
}

const Miniature: FC<Props> = ({
    image,
    width = 330,
    height = 220,
}) => {
    return (
        <CardMedia
            component="img"
            style={{
                width,
                height,
                backgroundColor: `rgba(0, 0, 0, 0.04)`,
                borderRadius: 4,
            }}
            image={image === "" ? miniature : image}
            alt="Miniature"
        />
    );
}

export default Miniature;