import { FC } from "react";
import { TableContactsRow } from "./models/TableContactsRow";
import Row from "../../components/layout/Row";
import { Button, Paper, Table, TableContainer } from "@mui/material";
import TableHead from "./TableHead";
import TableBody from "./TableBody";
import AdminBreadcrumbs, { PropsAdminBreadcrumbs } from "../../components/navigation/AdminBreadcrumbs/AdminBreadcrumbs";

interface Props {
    rows: TableContactsRow[];
    handleNavigateClick: () => void;
    breadcrumbs: PropsAdminBreadcrumbs
}

const Contacts: FC<Props> = ({
    rows,
    handleNavigateClick,
    breadcrumbs
}) => {
    return (
        <>
            <Row marginBottom={20} justifyContent="space-between">
                <AdminBreadcrumbs
                    home={breadcrumbs.home}
                    target={breadcrumbs.target}
                />

                <Button variant="contained" onClick={handleNavigateClick}>
                    Створити  контакт
                </Button>
            </Row>

            <TableContainer component={Paper}>
                <Table
                    sx={{ minWidth: 650 }}
                    aria-label="simple table"
                >
                    <TableHead />

                    <TableBody
                        rows={rows}
                    />
                </Table>
            </TableContainer>
        </>
    );
}

export default Contacts;