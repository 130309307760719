import { FC } from "react";
import Categories from "./Categories";
import { CategoryItem } from "./models/CategoryItem";
import { PropsAdminBreadcrumbs } from "../../components/navigation/AdminBreadcrumbs/AdminBreadcrumbs";
import { useDeleteCategoryMutation, useGetCategoriesQuery } from "../../store/services/categoriesApi";
import useActions from "../../hooks/useActions";
import useNavigateTo from "../../hooks/useNavigateTo";
import { RootPath } from "../../constans/app";

const CategoriesContainer: FC = () => {
    const { data, isError, isLoading } = useGetCategoriesQuery();
    const [deleteCategory, deleteActions] = useDeleteCategoryMutation();

    //@ts-ignore
    useActions([{ error: deleteActions.error, isSuccess: deleteActions.isSuccess }]);

    const categories: CategoryItem[] = data ? data?.map((category) => ({
        id: category.id,
        name: category.name.ua,
        key: category.type,
        delete: () => { deleteCategory(category.id) },
    })) : [];

    const handleNavigateClick = useNavigateTo("new");

    const breadcrumbs: PropsAdminBreadcrumbs = {
        home: {
            link: `/${RootPath}`,
            name: "Home"
        },
        target: "Categories"
    }

    return (
        <Categories
            categories={categories}
            breadcrumbs={breadcrumbs}
            handleNavigateClick={handleNavigateClick}
        />
    );
}

export default CategoriesContainer;