import { Box, Chip, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { MenuProps } from "./MenuProps";
import { FormState, UseFormGetFieldState, UseFormSetValue } from "react-hook-form";

export interface ChipValue {
    id: string;
    name: string;
}

interface Props {
    label: string;
    chips: ChipValue[];
    maxWidth?: number;
    marginRight?: number;
    marginBottom?: number;
    nameField: string;
    setValue: UseFormSetValue<any>;
    getFieldState: UseFormGetFieldState<any>;
    formState: FormState<any>;
    values: string;
}

const MultipleSelectChip: FC<Props> = ({
    label,
    chips,
    maxWidth = 575,
    marginRight,
    marginBottom,
    nameField,
    getFieldState,
    setValue,
    formState,
    values,
}) => {
    const { invalid } = getFieldState(nameField, formState);

    const [chipsName, setChipsName] = useState<string[]>([]);

    useEffect(() => {
        setChipsName(values.length > 0 ? values.split(",") : []);
    }, [values]);

    const handleChange = (event: SelectChangeEvent<typeof chipsName>) => {
        const {
            target: { value },
        } = event;

        setChipsName(typeof value === 'string' ? value.split(',') : value);

        let chipsValue = "";
        if (value.length > 1 && Array.isArray(value)) {
            chipsValue = value.join(",");
        } else if (value.length === 1 && Array.isArray(value)) {
            chipsValue = value[0];
        } else if (typeof value === "string") {
            chipsValue = value;
        }

        setValue(nameField, chipsValue, { shouldValidate: true });
    };

    const labelId = `${nameField}-chip-label`;

    return (
        <FormControl
            sx={{
                maxWidth,
                width: "100%"
            }}
            style={{
                marginRight,
                marginBottom
            }}
        >
            <InputLabel id={labelId}>
                {label}
            </InputLabel>

            <Select
                error={invalid}
                labelId={labelId}
                label={label}
                id={`${nameField}-chip`}
                multiple
                value={chipsName}
                onChange={handleChange}
                renderValue={(tags) => (
                    <Box
                        component="div"
                        sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
                    >
                        {tags.filter(tag => chips.findIndex((value) => value.id.localeCompare(tag) === 0) > -1).map((tag) => {
                            const indexChip = chips.findIndex((value) => value.id.localeCompare(tag) === 0);
                            const chip = chips[indexChip];

                            return (
                                <Chip key={`${chip?.id}-${labelId}-chip-item`} label={chip?.name} />
                            )
                        })}
                    </Box>
                )}
                MenuProps={MenuProps}
            >
                {chips.map((chip) => (
                    <MenuItem
                        key={`${chip.id}-${labelId}-select-item`}
                        value={chip.id}
                    >
                        {chip.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default MultipleSelectChip