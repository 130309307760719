import React, { FC } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

interface Props {
    toggleDrawer: (event: React.KeyboardEvent | React.MouseEvent) => void;
    handleExit: () => void;
}

const Header: FC<Props> = ({
    toggleDrawer,
    handleExit
}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const clickButtonExit = () => {
        handleClose();
        handleExit();
    }

    return (
        <AppBar
            position="sticky"
            sx={{ backgroundColor: "#293C4C" }}
        >
            <Toolbar>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2, color: "#DAD0C1" }}
                    onClick={toggleDrawer}
                >
                    <MenuIcon />
                </IconButton>
                <Typography
                    variant="h6"
                    component="h1"
                    sx={{
                        flexGrow: 1,
                        fontWeight: "300",
                        color: "#DAD0C1",
                    }}
                >
                    Pniv
                </Typography>
                <div>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        sx={{ color: "#DAD0C1" }}
                    >
                        <AccountCircle />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        keepMounted
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={clickButtonExit}>
                            <Typography
                                component="p"
                                sx={{
                                    flexGrow: 1,
                                    fontWeight: "500",
                                }}
                            >
                                Вийти
                            </Typography>
                        </MenuItem>
                    </Menu>
                </div>
            </Toolbar>
        </AppBar>
    );
}

export default Header;