import { FC, PropsWithChildren } from "react";

interface Props {
    index: number;
    value: number;
}

const TabPanel: FC<PropsWithChildren<Props>> = ({
    index,
    value,
    children,
}) => {

    return (
        <div
            role="tabpanel"
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
        >
            {children}
        </div>
    );
}

export default TabPanel;