import { Card } from "@mui/material";
import { FC, PropsWithChildren } from "react";

interface Props {
    height?: number;
}

const ContentCard: FC<PropsWithChildren<Props>> = ({
    children,
    height,
}) => {
    return (
        <Card
            component={"div"}
            sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height,
                backgroundColor: "#FFF",
                borderRadius: 4,
                padding: 1.9,
                marginBottom: 6,
                overflowY: "auto"
            }}
        >
            {children}
        </Card>
    );
}

export default ContentCard;