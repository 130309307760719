import React, { Dispatch, FC, SetStateAction, createContext, useState } from "react";
import { AlertColor, Container } from "@mui/material";
import { Outlet } from "react-router-dom";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import DrawerMenu from "../navigation/Menu/DrawerMenu";
import { MenuItem as MenuItemInteface } from "../navigation/Menu/MenuItem";
import Header from "../navigation/Header/Header";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { shallowEqual } from "react-redux";
import { logout } from "../../store/slices/auth.slice";
import CustomSnackbar from "../feedback/CustomSnackbar/CustomSnackbar";

type Feedback = {
    setOpen: (open: boolean) => void,
};

export const FeedbackContext = createContext<Feedback>({
    setOpen: (open) => { },
});

interface Props {
    menuItems: MenuItemInteface[];
    rootPath?: string;
}

const MainContainer: FC<Props> = ({
    menuItems,
    rootPath,
}) => {
    const dispatch = useAppDispatch();

    const user = useAppSelector((state) => state.userState.user, shallowEqual);

    const feedback = useAppSelector(state => state.appState.feedback, shallowEqual);

    const isAuth = user !== null;

    const [openFeedback, setOpenFeedback] = useState(false);
    const setOpen = (open: boolean) => { 
        setOpenFeedback(open) 
    };

    const [menuOpen, setMenuOpen] = useState(false);

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setMenuOpen(open);
    };

    const handleExit = () => {
        dispatch(logout());
    }

    return (
        <FeedbackContext.Provider value={{
            setOpen,
        }}>
            {isAuth &&
                <>
                    <Header
                        handleExit={handleExit}
                        toggleDrawer={toggleDrawer(true)}
                    />

                    <DrawerMenu
                        menuOpen={menuOpen}
                        toggleDrawer={toggleDrawer(false)}
                        menuItems={menuItems}
                        rootPath={rootPath}
                    />
                </>
            }

            <Container
                maxWidth="lg"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    paddingTop: 5
                }}
            >
                <Outlet />

                <CustomSnackbar
                    open={openFeedback}
                    setOpen={setOpen}
                    message={feedback.message || ""}
                    severity={feedback.type || "success"}
                    autoHideDuration={feedback.autoHideDuration}
                />
            </Container>
        </FeedbackContext.Provider>
    );
}

export default MainContainer;