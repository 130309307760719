import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BaseUrl, Langs, ResponseError, ResponsesStatus } from './constans';
import prepareHeaders from '../../utl/prepareHeaders';

export interface Page {
    id: number;
    name: Langs;
    description: Langs;
}

export type PageId = Pick<Page, "id">;

export type CreatePage = Omit<Page, "id">;

export const PageTag = "Page";

export const pagesApi = createApi({
    reducerPath: 'pagesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BaseUrl}/pages/`,
        prepareHeaders,
        credentials: 'include',
    }),
    tagTypes: [PageTag],
    endpoints: (build) => ({
        getPages: build.query<Page[], void>({
            query: () => "",
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ id }) => ({ type: PageTag, id } as const)),
                        { type: PageTag, id: 'LIST' },
                    ]
                    :
                    [{ type: PageTag, id: 'LIST' }],
            transformResponse: (response: { pages: Page[] }) => response.pages,
        }),
        getPage: build.query<Page, number>({
            query: (id) => `${id}`,
            providesTags: (result, error, id) => [{ type: PageTag, id }],
            transformResponse: (response: { page: Page }) => response.page,
        }),
        createPage: build.mutation<PageId, CreatePage>({
            query: (body) => {
                return {
                    url: "",
                    method: "POST",
                    body,
                }
            },
            invalidatesTags: [{ type: PageTag, id: 'LIST' }],
            transformErrorResponse: (response: ResponseError) => response,
        }),
        updatePage: build.mutation<Page, Page>({
            query: (body) => {
                return {
                    url: `${body.id}`,
                    method: "PUT",
                    body,
                }
            },
            invalidatesTags: (result, error, { id }) =>
                result
                    ? [
                        { type: PageTag, id },
                        { type: PageTag, id: 'LIST' },
                    ]
                    :
                    [{ type: PageTag, id: 'LIST' }],
            transformErrorResponse: (response: ResponseError) => response,

        }),
        deletePage: build.mutation<ResponsesStatus, number>({
            query: (id) => {
                return {
                    url: `${id}`,
                    method: "DELETE",
                }
            },
            invalidatesTags: [{ type: PageTag, id: 'LIST' }],
            transformErrorResponse: (response: ResponseError) => response,
        })
    }),
});

export const {
    useGetPagesQuery,
    useGetPageQuery,
    useCreatePageMutation,
    useUpdatePageMutation,
    useDeletePageMutation,
} = pagesApi;