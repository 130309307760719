import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BaseUrl, Langs, ResponseError, ResponsesStatus } from './constans';
import prepareHeaders from '../../utl/prepareHeaders';

export interface Service {
    id: number;
    name: Langs;
    description: Langs;
    coordinates: string;
    miniature: string;
    categoriesIds: number[];
}

export type ServiceId = Pick<Service, "id">;

export type CreateService = Omit<Service, "id">;

export const PlaceTag = "Place";

export const servicesApi = createApi({
    reducerPath: 'servicesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BaseUrl}/services/`,
        prepareHeaders,
        credentials: 'include',
    }),
    tagTypes: [PlaceTag],
    endpoints: (build) => ({
        getServices: build.query<Service[], void>({
            query: () => "",
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ id }) => ({ type: PlaceTag, id } as const)),
                        { type: PlaceTag, id: 'LIST' },
                    ]
                    :
                    [{ type: PlaceTag, id: 'LIST' }],
            transformResponse: (response: { services: Service[] }) => response.services,
        }),
        getService: build.query<Service, number>({
            query: (id) => `${id}`,
            providesTags: (result, error, id) => [{ type: PlaceTag, id }],
            transformResponse: (response: { service: Service }) => response.service,
        }),
        createService: build.mutation<ServiceId, CreateService>({
            query: (body) => {
                return {
                    url: "",
                    method: "POST",
                    body,
                }
            },
            invalidatesTags: [{ type: PlaceTag, id: 'LIST' }],
            transformErrorResponse: (response: ResponseError) => response,
        }),
        updateService: build.mutation<Service, Service>({
            query: (body) => {
                return {
                    url: `${body.id}`,
                    method: "PUT",
                    body,
                }
            },
            invalidatesTags: (result, error, { id }) =>
                result
                    ? [
                        { type: PlaceTag, id },
                        { type: PlaceTag, id: 'LIST' },
                    ]
                    :
                    [{ type: PlaceTag, id: 'LIST' }],
            transformErrorResponse: (response: ResponseError) => response,
        }),
        deleteService: build.mutation<ResponsesStatus, number>({
            query: (id) => {
                return {
                    url: `${id}`,
                    method: "DELETE",
                }
            },
            invalidatesTags: [{ type: PlaceTag, id: 'LIST' }],
            transformErrorResponse: (response: ResponseError) => response,
        })
    }),
});

export const {
    useGetServicesQuery,
    useGetServiceQuery,
    useCreateServiceMutation,
    useUpdateServiceMutation,
    useDeleteServiceMutation,
} = servicesApi;