import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import { MENU_ITEMS } from "./constans/menu";
import MainContainer from "./components/layout/MainContainer";
import WelcomeScreen from "./screens/WelcomeScreen/WelcomeScreen";
import ToursContainer from "./screens/Tours/Tours.container";
import TourContainer from "./screens/Tours/Tour/Tour.container";
import GuidesContainer from "./screens/Guides/Guides.container";
import GuideContainer from "./screens/Guides/Guide/Guide.container";
import ContactsContainer from "./screens/Contacts/Contacts.container";
import ContactContainer from "./screens/Contacts/Contact/Contact.container";
import PlacesContainer from "./screens/Places/Places.container";
import PlaceContainer from "./screens/Places/Place/Place.container";
import ServicesContainer from "./screens/Services/Services.container";
import ServiceContainer from "./screens/Services/Service/Service.container";
import AboutAppContainer from "./screens/AboutApp/AboutApp.container";
import CategoriesContainer from "./screens/Categories/Categories.container";
import CategoryContainer from "./screens/Categories/Category/Category.container";
import LoginContainer from "./screens/Login/Login.container";
import RequireAuth from "./hoc/RequireAuth";
import { BasicPath, GlobalPath, RootPath } from "./constans/app";

function App() {
  return (
    <Routes>
        <Route path={`/${GlobalPath + BasicPath}`}
          element={
            <MainContainer rootPath={RootPath} menuItems={MENU_ITEMS} />
          }
        >
          <Route
            index
            element={
              <RequireAuth baseUrl={RootPath}>
                <WelcomeScreen />
              </RequireAuth>
            }
          />
          <Route path="tours">
            <Route
              index
              element={
                <RequireAuth baseUrl={RootPath}>
                  <ToursContainer />
                </RequireAuth>
              }
            />
            <Route
              path=":id"
              element={
                <RequireAuth baseUrl={RootPath}>
                  <TourContainer />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="guides">
            <Route index element={<RequireAuth baseUrl={RootPath}><GuidesContainer /></RequireAuth>} />
            <Route path=":id" element={<RequireAuth baseUrl={RootPath}><GuideContainer /></RequireAuth>} />
            <Route path="new" element={<RequireAuth baseUrl={RootPath}><GuideContainer isNewGuide={true} /></RequireAuth>} />
          </Route>
          <Route path="contacts">
            <Route index element={<RequireAuth baseUrl={RootPath}><ContactsContainer /></RequireAuth>} />
            <Route path=":id" element={<RequireAuth baseUrl={RootPath}><ContactContainer /></RequireAuth>} />
            <Route
              path="new"
              element={
                <RequireAuth
                  baseUrl={RootPath}
                >
                  <ContactContainer isNewContact={true} />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="places">
            <Route index element={<RequireAuth baseUrl={RootPath}><PlacesContainer /></RequireAuth>} />
            <Route path=":id" element={<RequireAuth baseUrl={RootPath}><PlaceContainer /></RequireAuth>} />
            <Route
              path="new"
              element={
                <RequireAuth baseUrl={RootPath}>
                  <PlaceContainer isNewPlace={true} />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="services">
            <Route index element={<RequireAuth baseUrl={RootPath}><ServicesContainer /></RequireAuth>} />
            <Route path=":id" element={<RequireAuth baseUrl={RootPath}><ServiceContainer /></RequireAuth>} />
            <Route path="new" element={<RequireAuth baseUrl={RootPath}><ServiceContainer isNewService={true} /></RequireAuth>} />
          </Route>
          <Route path="categories">
            <Route index element={<RequireAuth baseUrl={RootPath}><CategoriesContainer /></RequireAuth>} />
            <Route
              path="new"
              element={
                <RequireAuth baseUrl={RootPath}>
                  <CategoryContainer isNewCategory={true} />
                </RequireAuth>
              }
            />
            <Route path=":id" element={<RequireAuth baseUrl={RootPath}><CategoryContainer /></RequireAuth>} />
          </Route>
          <Route
            path="about-app"
            element={
              <RequireAuth baseUrl={RootPath}>
                <AboutAppContainer />
              </RequireAuth>
            }
          />
          <Route path="login" element={<LoginContainer baseUrl={RootPath} />} />
        </Route>
    </Routes>
  );
}

export default App;
