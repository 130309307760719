import { useNavigate } from "react-router-dom";
import Services from "./Services";
import { TableSerivesRow } from "./models/TableSerivesRow";
import { PropsAdminBreadcrumbs } from "../../components/navigation/AdminBreadcrumbs/AdminBreadcrumbs";
import { useDeleteServiceMutation, useGetServicesQuery } from "../../store/services/servicesApi";
import useActions from "../../hooks/useActions";
import useNavigateTo from "../../hooks/useNavigateTo";
import { RootPath } from "../../constans/app";

const ServicesContainer = () => {
    const { data, isLoading, isError } = useGetServicesQuery();
    const [deleteService, deleteActions] = useDeleteServiceMutation();

    //@ts-ignore
    useActions([{error: deleteActions.error, isSuccess: deleteActions.isSuccess }]);

    const rows: TableSerivesRow[] = data ? data?.map((services) => ({
        id: services.id,
        name: services.name.ua,
        miniature: services.miniature,
        action: () => { deleteService(services.id) },
    })) : [];

    const handleNavigateClick = useNavigateTo('new');

    const breadcrumbs: PropsAdminBreadcrumbs = {
        home: {
            link: `/${RootPath}`,
            name: "Home"
        },
        target: "Services"
    }

    return (
        <Services
            rows={rows}
            handleNavigateClick={handleNavigateClick}
            breadcrumbs={breadcrumbs}
        />
    )
}

export default ServicesContainer;