import { Typography } from "@mui/material";
import { FC } from "react";
import robot from "../../assets/svg/Robot.svg";

const WelcomeScreen: FC = () => {
    return (
        <>
            <img src={robot} style={{ marginBottom: 80 }} />
            <Typography
                component="p"
                fontWeight="300"
                color="#000"
                textAlign="center"
                maxWidth={500}
                width="100%"
            >
                Дякую! Ви увійшли до адмін панелі проекту Pniv. Натисніть на елемент щоб перейти до редагування контенту.
            </Typography>
        </>
    );
}

export default WelcomeScreen;