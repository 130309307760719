import { BaseSyntheticEvent, FC, SyntheticEvent, useState } from "react";
import TabsContainer from "../../components/Tabs.container";
import ContentCard from "../../../components/surfaces/ContentCard";
import SlideViews from "../../../components/navigation/TabsMenu/SlideViews/SlideViews";
import TabPanel from "../../../components/navigation/TabsMenu/TabPanel";
import Textarea from "../../../components/inputs/Textarea/Textarea";
import Input from "../../../components/inputs/Input/Input";
import Row from "../../../components/layout/Row";
import AdminBreadcrumbs, { PropsAdminBreadcrumbs } from "../../../components/navigation/AdminBreadcrumbs/AdminBreadcrumbs";
import FormButtons from "../../../components/buttons/FormButtons";
import { ContactFormData } from "./models/ContactFormData";
import { Control } from "react-hook-form";

interface Props {
    breadcrumbs: PropsAdminBreadcrumbs;
    onSave: (e?: BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
    onDelete?: (e?: BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
    control: Control<ContactFormData, any>;
    isNewContact?: boolean;
}

const Contact: FC<Props> = ({
    breadcrumbs,
    onSave,
    onDelete,
    control,
    isNewContact
}) => {
    const [indexTab, setIndexTab] = useState(0);

    const handleChange = (event: SyntheticEvent, newIndex: number) => {
        setIndexTab(newIndex);
    };

    return (
        <>
            <Row marginBottom={20} justifyContent="flex-start">
                <AdminBreadcrumbs
                    home={breadcrumbs.home}
                    target={breadcrumbs.target}
                />
            </Row>

            <TabsContainer value={indexTab} handleChange={handleChange} />

            <ContentCard
                height={270}
            >
                <SlideViews
                    currentIndex={indexTab}
                >
                    <TabPanel value={indexTab} index={0}>
                        <Input
                            label="Назва"
                            marginTop={5}
                            marginBottom={15}
                            nameField="nameUA"
                            control={control}
                        />

                        <Textarea
                            label="Опис"
                            rows={1}
                            marginBottom={15}
                            nameField="descriptionUA"
                            control={control}
                        />

                        <Input
                            label="Назва посилання на контакт в Інтернеті"
                            marginBottom={0}
                            nameField="nameOfLinkUA"
                            control={control}
                        />
                    </TabPanel>

                    <TabPanel value={indexTab} index={1}>
                        <Input
                            label="Name"
                            marginTop={5}
                            marginBottom={15}
                            nameField="nameEN"
                            control={control}
                        />

                        <Textarea
                            label="Description"
                            rows={1}
                            marginBottom={15}
                            nameField="descriptionEN"
                            control={control}
                        />

                        <Input
                            label="The name of the link to the contact on the Internet"
                            marginBottom={0}
                            nameField="nameOfLinkEN"
                            control={control}
                        />
                    </TabPanel>
                </SlideViews>
            </ContentCard>

            <ContentCard
                height={135}
            >
                <Row
                    justifyContent="space-between"
                    alignItems="center"
                    marginBottom={10}
                >
                    <Input
                        width={400}
                        label="Номер Телефону"
                        marginBottom={0}
                        marginRight={40}
                        nameField="phone"
                        control={control}
                    />

                    <Input
                        maxWidth={795}
                        label="Посилання на контакт в інтернеті"
                        marginBottom={0}
                        nameField="linkOnContact"
                        control={control}
                    />
                </Row>

                <FormButtons
                    onDelete={onDelete}
                    onSave={onSave}
                    isNewItem={isNewContact}
                />
            </ContentCard>
        </>
    );
}

export default Contact;