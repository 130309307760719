import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { AccessToken, BaseUrl, ExpiresIn } from './constans';
import { setUser } from '../slices/auth.slice';
import { LoginFormData } from '../../screens/Login/models/LoginFormData';

export interface User {
    email: string;
    expiresIn: number;
    accessToken: string;
    role: string;
}

export type LoginUser = Omit<User, "role">;

export interface RegisterInput {
    email: string;
    password: string;
    role: string;
}

export const UserTag = "User";

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: fetchBaseQuery({ baseUrl: `${BaseUrl}/auth/` }),
    tagTypes: [UserTag],
    endpoints: (build) => ({
        login: build.mutation<LoginUser, LoginFormData>({
            query(body) {
                const { email, password } = body;

                return {
                    url: `login`,
                    method: 'POST',
                    body: {
                        email,
                        password,
                    },
                }
            },
            invalidatesTags: [{ type: UserTag }],
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    const { data: { email, expiresIn, accessToken } } = await queryFulfilled;

                    dispatch(setUser({ email, accessToken, expiresIn }));
                } catch (error) { }
            },
        }),
        register: build.mutation<User, RegisterInput>({
            query(body) {
                const { email, password, role } = body;

                return {
                    url: `register`,
                    method: 'POST',
                    body: {
                        email,
                        password,
                        role
                    },
                }
            },
            invalidatesTags: [{ type: UserTag }],
        }),
    }),
});

export const {
    useLoginMutation,
    useRegisterMutation
} = authApi;