import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Box } from "@mui/system";
import React, { FC } from "react";
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { MenuItem } from "./MenuItem";
import { useNavigate } from "react-router-dom";

interface Props {
    menuItems: MenuItem[];
    toggleDrawer: (event: React.KeyboardEvent | React.MouseEvent) => void;
    rootPath?: string; 
}

const MenuList: FC<Props> = ({
    menuItems,
    toggleDrawer,
    rootPath = ""
}) => {
    const navigate = useNavigate();

    return (
        <Box
            component="div"
            sx={{ width: 250, }}
            role="presentation"
            onClick={toggleDrawer}
            onKeyDown={toggleDrawer}
        >
            <List>
                {menuItems.map((menuItem, index) => {
                    const { name, link } = menuItem;

                    const listItemButtonHandler = () => {
                        navigate(`/${rootPath}${link}/`);
                    }

                    return (
                        <ListItem key={menuItem.link} disablePadding>
                            <ListItemButton onClick={listItemButtonHandler}>
                                <ListItemIcon>
                                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                                </ListItemIcon>
                                <ListItemText primary={name} />
                            </ListItemButton>
                        </ListItem>
                    )
                })}
            </List>
        </Box>
    );
}

export default MenuList;