import { Typography } from "@mui/material";
import { FC } from "react";

interface Props {
    name: string;
}

const H3: FC<Props> = ({
    name
}) => {
    return (
        <Typography
            variant="h3"
            style={{ 
                fontSize: 16, 
                marginBottom: 15 
            }}
        >
            {name}
        </Typography>
    );
}

export default H3;