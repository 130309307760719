import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BaseUrl, Langs, ResponseError, ResponsesStatus } from './constans';
import { SelectItem } from '../../components/inputs/BasicSelect/models/SelectItem';
import prepareHeaders from '../../utl/prepareHeaders';

export interface Category {
    id: number;
    name: Langs;
    type: string;
    icon: string;
}

export type CategoryId = Pick<Category, "id">;

export type CreateCategory = Omit<Category, "id">;

export const CategoryTag = "Category";

export const categoriesApi = createApi({
    reducerPath: 'categoriesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BaseUrl}/categories/`,
        prepareHeaders,
        credentials: 'include',
    }),
    tagTypes: [CategoryTag],
    endpoints: (build) => ({
        getCategories: build.query<Category[], void>({
            query: () => "",
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ id }) => ({ type: CategoryTag, id } as const)),
                        { type: CategoryTag, id: 'LIST' },
                    ]
                    :
                    [{ type: CategoryTag, id: 'LIST' }],
            transformResponse: (response: { categories: Category[] }) => response.categories,
        }),
        getCategory: build.query<Category, number>({
            query: (id) => `${id}`,
            providesTags: (result, error, id) => [{ type: CategoryTag, id }],
            transformResponse: (response: { category: Category }) => response.category,
        }),
        getCategoriesList: build.query<SelectItem[], void>({
            query: () => `list`,
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ key }) => ({ type: CategoryTag, id: key } as const)),
                        { type: CategoryTag, id: 'LIST' },
                    ]
                    :
                    [{ type: CategoryTag, id: 'LIST' }],
            transformResponse: (response: { list: SelectItem[] }) => response.list,
        }),
        createCategory: build.mutation<CategoryId, CreateCategory>({
            query: (body) => {
                return {
                    url: "",
                    method: "POST",
                    body,
                }
            },
            invalidatesTags: [{ type: CategoryTag, id: 'LIST' }],
            transformErrorResponse: (response: ResponseError) => response,
        }),
        updateCategory: build.mutation<Category, Category>({
            query: (body) => {
                return {
                    url: `${body.id}`,
                    method: "PUT",
                    body,
                }
            },
            invalidatesTags: (result, error, { id }) =>
                result
                    ? [
                        { type: CategoryTag, id },
                        { type: CategoryTag, id: 'LIST' },
                    ]
                    :
                    [{ type: CategoryTag, id: 'LIST' }],
            transformErrorResponse: (response: ResponseError) => response,
        }),
        deleteCategory: build.mutation<ResponsesStatus, number>({
            query: (id) => {
                return {
                    url: `${id}`,
                    method: "DELETE",
                }
            },
            invalidatesTags: [{ type: CategoryTag, id: 'LIST' }],
            transformErrorResponse: (response: ResponseError) => response,
        })
    }),
});

export const {
    useGetCategoriesQuery,
    useGetCategoryQuery,
    useGetCategoriesListQuery,
    useCreateCategoryMutation,
    useDeleteCategoryMutation,
    useUpdateCategoryMutation
} = categoriesApi;