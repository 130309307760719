import { BaseSyntheticEvent, FC, SyntheticEvent, useState } from "react";
import TabsContainer from "../../components/Tabs.container";
import ContentCard from "../../../components/surfaces/ContentCard";
import SlideViews from "../../../components/navigation/TabsMenu/SlideViews/SlideViews";
import Input from "../../../components/inputs/Input/Input";
import Row from "../../../components/layout/Row";
import TabPanel from "../../../components/navigation/TabsMenu/TabPanel";
import AdminBreadcrumbs, { PropsAdminBreadcrumbs } from "../../../components/navigation/AdminBreadcrumbs/AdminBreadcrumbs";
import { CategoryFormData } from "./models/CategoryFormData";
import { Control, FormState, UseFormGetFieldState, UseFormGetValues, UseFormSetValue } from "react-hook-form";
import FormButtons from "../../../components/buttons/FormButtons";
import BasicSelect from "../../../components/inputs/BasicSelect/BasicSelect";
import { IconType } from "../../../constans/IconType";

interface Props {
    breadcrumbs: PropsAdminBreadcrumbs;
    onSave: (e?: BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
    onDelete?: (e?: BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
    control: Control<CategoryFormData, any>;
    isNewCategory?: boolean;
    setValue: UseFormSetValue<CategoryFormData>;
    getFieldState: UseFormGetFieldState<CategoryFormData>;
    formState: FormState<CategoryFormData>;
    iconValue: string;
}

const Category: FC<Props> = ({
    breadcrumbs,
    onSave,
    onDelete,
    control,
    isNewCategory,
    setValue,
    getFieldState,
    formState,
    iconValue
}) => {
    const [indexTab, setIndexTab] = useState(0);

    const handleChange = (event: SyntheticEvent, newIndex: number) => {
        setIndexTab(newIndex);
    };

    return (
        <>
            <Row marginBottom={20} justifyContent="space-between">
                <AdminBreadcrumbs
                    home={breadcrumbs.home}
                    target={breadcrumbs.target}
                />
            </Row>

            <TabsContainer value={indexTab} handleChange={handleChange} />

            <ContentCard
                height={80}
            >
                <SlideViews
                    currentIndex={indexTab}
                >
                    <TabPanel value={indexTab} index={0}>
                        <Input
                            label="Назва"
                            marginTop={5}
                            nameField="nameUA"
                            control={control}
                        />
                    </TabPanel>

                    <TabPanel value={indexTab} index={1}>
                        <Input
                            label="Name"
                            marginTop={5}
                            nameField="nameEN"
                            control={control}
                        />
                    </TabPanel>
                </SlideViews>
            </ContentCard>

            <ContentCard
                height={300}
            >
                <Input
                    label="Ключ"
                    marginTop={5}
                    marginBottom={10}
                    width={425}
                    nameField="key"
                    control={control}
                />

                <BasicSelect
                    label="Іконка"
                    selects={IconType}
                    marginBottom={25}
                    getFieldState={getFieldState}
                    formState={formState}
                    setValue={setValue}
                    nameField="icon"
                    value={iconValue}
                    withIcon={true}
                />

                <FormButtons
                    onSave={onSave}
                    onDelete={onDelete}
                    isNewItem={isNewCategory}
                />
            </ContentCard>
        </>
    )
}

export default Category;