import { FC } from "react";
import SlideViewsItem from "./SlideViewsItem";

interface Props {
    children: JSX.Element[];
    currentIndex: number;
    height?: number | string;
    marginBottom?: number;
}

const SlideViews: FC<Props> = ({
    children,
    currentIndex,
    height = "100%",
    marginBottom,
}) => {
    const childrenWithAnimation = children.map((child, index) => (
        <SlideViewsItem
            key={"slide-views-item-" + index}
            index={index}
            currentIndex={currentIndex}
        >
            {child}
        </SlideViewsItem>
    ));

    return (
        <div style={{
            position: "relative",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            width: "100%",
            height,
            marginBottom,
            overflow: "hidden"
        }}>
            {childrenWithAnimation}
        </div>
    );
}

export default SlideViews;