import { BaseSyntheticEvent, FC, SyntheticEvent, useState } from "react";
import TabsContainer from "../../components/Tabs.container";
import ContentCard from "../../../components/surfaces/ContentCard";
import SlideViews from "../../../components/navigation/TabsMenu/SlideViews/SlideViews";
import TabPanel from "../../../components/navigation/TabsMenu/TabPanel";
import Textarea from "../../../components/inputs/Textarea/Textarea";
import Input from "../../../components/inputs/Input/Input";
import Row from "../../../components/layout/Row";
import AdminBreadcrumbs, { PropsAdminBreadcrumbs } from "../../../components/navigation/AdminBreadcrumbs/AdminBreadcrumbs";
import { GuideFormData } from "./models/GuideFormData";
import { Control } from "react-hook-form";
import FormButtons from "../../../components/buttons/FormButtons";

interface Props {
    breadcrumbs: PropsAdminBreadcrumbs;
    onSave: (e?: BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
    onDelete?: (e?: BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
    control: Control<GuideFormData, any>;
    isNewGuide?: boolean;
}

const Guide: FC<Props> = ({
    breadcrumbs,
    onSave,
    onDelete,
    control,
    isNewGuide,
}) => {
    const [indexTab, setIndexTab] = useState(0);

    const handleChange = (event: SyntheticEvent, newIndex: number) => {
        setIndexTab(newIndex);
    };

    return (
        <>
            <Row marginBottom={20} justifyContent="flex-start">
                <AdminBreadcrumbs
                    home={breadcrumbs.home}
                    target={breadcrumbs.target}
                />
            </Row>

            <TabsContainer value={indexTab} handleChange={handleChange} />

            <ContentCard
                height={175}
            >
                <SlideViews
                    currentIndex={indexTab}
                >
                    <TabPanel value={indexTab} index={0}>
                        <Input
                            label="Назва"
                            marginTop={5}
                            nameField="nameUA"
                            control={control}
                            marginBottom={15}
                        />

                        <Textarea
                            label="Опис"
                            rows={1}
                            nameField="descriptionUA"
                            control={control}
                            marginBottom={15}
                        />
                    </TabPanel>

                    <TabPanel value={indexTab} index={1}>
                        <Input
                            label="Name"
                            marginTop={5}
                            nameField="nameEN"
                            control={control}
                        />

                        <Textarea
                            label="Description"
                            rows={1}
                            nameField="descriptionEN"
                            control={control}
                        />
                    </TabPanel>
                </SlideViews>
            </ContentCard>

            <ContentCard
                height={145}
            >
                <Input
                    width={335}
                    label="Номер Телефону"
                    marginTop={5}
                    marginBottom={10}
                    nameField="phone"
                    control={control}
                />

                <FormButtons
                    onDelete={onDelete}
                    onSave={onSave}
                    isNewItem={isNewGuide}
                />
            </ContentCard>
        </>
    );
}

export default Guide;