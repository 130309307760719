import { FC, useEffect } from "react";
import AboutApp from "./AboutApp";
import { PropsAdminBreadcrumbs } from "../../components/navigation/AdminBreadcrumbs/AdminBreadcrumbs";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import AboutAppSchema from "./schema/AboutApp.schema";
import { AboutAppFormData } from "./models/AboutAppFormData";
import { useGetPageQuery, useUpdatePageMutation } from "../../store/services/pagesApi";
import useActions from "../../hooks/useActions";
import { RootPath } from "../../constans/app";

const defaultValues: AboutAppFormData = {
    descriptionUA: "",
    descriptionEN: "",
}

const AboutAppContainer: FC = () => {
    const aboutAppId = 1;

    const { data, isLoading } = useGetPageQuery(aboutAppId);

    const page = data;

    const [updatePage, updateAction] = useUpdatePageMutation();

    //@ts-ignore
    useActions([{ error: updateAction.error, isSuccess: updateAction.isSuccess }]);

    const targetPage = page?.name?.en || "None";

    const breadcrumbs: PropsAdminBreadcrumbs = {
        home: {
            link: `/${RootPath}`,
            name: "Home"
        },
        target: targetPage
    }

    const { handleSubmit, reset, control, setValue, formState } = useForm<AboutAppFormData>({
        defaultValues,
        resolver: yupResolver(AboutAppSchema),
        mode: "all",
    });

    useEffect(() => {
        if (!isLoading && page) {
            setValue("descriptionUA", page.description.ua);
            setValue("descriptionEN", page.description.en);
        }
    }, [isLoading, page]);

    const onSave = handleSubmit(data => {
        const {
            descriptionEN,
            descriptionUA,
        } = data;

        const descriptionUAWithReplace = descriptionUA.replaceAll('"', '\\"');
        const descriptionENWithReplace = descriptionEN.replaceAll('"', '\\"');

        updatePage({
            id: aboutAppId,
            name: {
                ua: "Про Додаток",
                en: "About App",
            },
            description: {
                ua: descriptionUAWithReplace,
                en: descriptionENWithReplace
            }
        });
    });
    return (
        <AboutApp
            breadcrumbs={breadcrumbs}
            onSave={onSave}
            control={control}
        />
    )
}

export default AboutAppContainer;