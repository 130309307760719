import { Paper, Table, TableContainer } from "@mui/material";
import { FC } from "react";
import { TableToursRow } from "./models/TableToursRow";
import TableBody from "./TableBody";
import TableHead from "./TableHead";
import Row from "../../components/layout/Row";
import AdminBreadcrumbs, { PropsAdminBreadcrumbs } from "../../components/navigation/AdminBreadcrumbs/AdminBreadcrumbs";

interface Props {
    rows: TableToursRow[];
    breadcrumbs: PropsAdminBreadcrumbs;
}

const Tours: FC<Props> = ({
    rows,
    breadcrumbs
}) => {
    return (
        <>
            <Row justifyContent="flex-start" marginBottom={20}>
                <AdminBreadcrumbs 
                    home={breadcrumbs.home} 
                    target={breadcrumbs.target}
                />
            </Row>
            
            <TableContainer component={Paper}>
                <Table
                    sx={{ minWidth: 650 }}
                    aria-label="simple table"
                >
                    <TableHead />

                    <TableBody
                        rows={rows}
                    />
                </Table>
            </TableContainer>
        </>
    );
}

export default Tours;