import { FC, PropsWithChildren } from "react";

interface Props {
    justifyContent?: string;
    alignItems?: string;
    width?: string | number;
    marginBottom?: number;
}

const Row: FC<PropsWithChildren<Props>> = ({
    children,
    justifyContent = "flex-start",
    alignItems = "flex-start",
    width = "100%",
    marginBottom
}) => {
    return (
        <div style={{
            display: "flex",
            justifyContent,
            alignItems,
            width,
            marginBottom
        }}>
            {children}
        </div>
    )
}

export default Row;