import { useNavigate } from "react-router-dom";

const useNavigateTo = (to: string) => {
    const navigate = useNavigate();

    const handleNavigateClick = () => {
        navigate(to);
    }

    return handleNavigateClick;
}

export default useNavigateTo;