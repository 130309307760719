import { MenuItem } from "../components/navigation/Menu/MenuItem";

const MENU_ITEMS: MenuItem[] = [
    {
        name: "Tours",
        link: "tours"
    },
    {
        name: "Guides",
        link: "guides"
    },
    {
        name: "Useful Contacts",
        link: "contacts"
    },
    {
        name: "Places",
        link: "places"
    },
    {
        name: "Services",
        link: "services"
    },
    {
        name: "Categories",
        link: "categories"
    },
    {
        name: "About App",
        link: "about-app"
    }
];

export { MENU_ITEMS };