import { BaseSyntheticEvent, FC, SyntheticEvent, useState } from "react";
import Row from "../../components/layout/Row";
import { Box, Button } from "@mui/material";
import TabsContainer from "../components/Tabs.container";
import ContentCard from "../../components/surfaces/ContentCard";
import SlideViews from "../../components/navigation/TabsMenu/SlideViews/SlideViews";
import TabPanel from "../../components/navigation/TabsMenu/TabPanel";
import Textarea from "../../components/inputs/Textarea/Textarea";
import AdminBreadcrumbs, { PropsAdminBreadcrumbs } from "../../components/navigation/AdminBreadcrumbs/AdminBreadcrumbs";
import { Control } from "react-hook-form";
import { AboutAppFormData } from "./models/AboutAppFormData";
import FormButtons from "../../components/buttons/FormButtons";

export interface Props {
    breadcrumbs: PropsAdminBreadcrumbs;
    onSave: (e?: BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
    control: Control<AboutAppFormData, any>;
}

const AboutApp: FC<Props> = ({
    breadcrumbs,
    onSave,
    control,
}) => {
    const [indexTab, setIndexTab] = useState(0);

    const handleChange = (event: SyntheticEvent, newIndex: number) => {
        setIndexTab(newIndex);
    };

    return (
        <>
            <Row marginBottom={20} justifyContent="space-between">
                <AdminBreadcrumbs
                    home={breadcrumbs.home}
                    target={breadcrumbs.target}
                />
            </Row>

            <TabsContainer value={indexTab} handleChange={handleChange} />

            <ContentCard
                height={730}
            >
                <SlideViews
                    currentIndex={indexTab}
                    height={650}
                    marginBottom={25}
                >
                    <TabPanel value={indexTab} index={0}>
                        <Textarea
                            label="Опис"
                            rows={25}
                            marginTop={5}
                            nameField="descriptionUA"
                            control={control}
                        />
                    </TabPanel>

                    <TabPanel value={indexTab} index={1}>
                        <Textarea
                            label="Description"
                            rows={25}
                            marginTop={5}
                            nameField="descriptionEN"
                            control={control}
                        />
                    </TabPanel>
                </SlideViews>

                <FormButtons
                    onSave={onSave}
                />
            </ContentCard>

        </>
    )
}

export default AboutApp;