import { Box, Tab, Tabs } from "@mui/material";
import { a11yProps } from "./a11yProps";
import { FC } from "react";

interface Props {
    value: number;
    handleChange: (event: React.SyntheticEvent, indexTab: number) => void;
    tabs: string[];
}

const TabsMenu: FC<Props> = ({
    value,
    handleChange,
    tabs
}) => {
    const tabList = tabs.map((tab, index) => (
        <Tab key={"tabs-menu-" + index} label={tab} {...a11yProps(index)} />
    ));

    return (
        <Box
            width="100%"
            component="div"
            style={{ marginBottom: 25 }}
            role="presentation"
        >
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                {tabList}
            </Tabs>
        </Box>
    );
}

export default TabsMenu;