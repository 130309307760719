import * as yup from "yup";

const LoginSchema = yup.object({
    email: yup.string().email("Це повинна бути пошта").required("Це поле обов'язкове"),
    password: yup.string()
        .min(8, "Пароль має бути більше 8 символів")
        .max(32, "Пароль має бути менше 32 символів")
        .required("Це поле обов'язкове"),
}).required();

export default LoginSchema;