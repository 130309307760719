import { FC, useEffect, useState } from "react";
import Category from "./Category";
import { PropsAdminBreadcrumbs } from "../../../components/navigation/AdminBreadcrumbs/AdminBreadcrumbs";
import { useForm } from "react-hook-form";
import { CategoryFormData } from "./models/CategoryFormData";
import { yupResolver } from '@hookform/resolvers/yup';
import CategorySchema from "./schema/Category.schema";
import { useParams } from "react-router-dom";
import {
    useCreateCategoryMutation,
    useDeleteCategoryMutation,
    useGetCategoryQuery,
    useUpdateCategoryMutation
} from "../../../store/services/categoriesApi";
import useActions from "../../../hooks/useActions";
import useNavigateTo from "../../../hooks/useNavigateTo";
import useAction from "../../../hooks/useAction";
import { RootPath } from "../../../constans/app";

const defaultValues: CategoryFormData = {
    nameEN: "",
    nameUA: "",
    key: "",
    icon: "",
}

interface Props {
    isNewCategory?: boolean;
}

const CategoryContainer: FC<Props> = ({
    isNewCategory
}) => {
    let { id } = useParams();

    const { data, isLoading, isError } = useGetCategoryQuery(Number(id));

    const category = data;

    const [createCategory, createActions] = useCreateCategoryMutation();
    const [updateCategory, updateAction] = useUpdateCategoryMutation();
    const [deleteCategory, deleteActions] = useDeleteCategoryMutation();

    //@ts-ignore
    useActions([{ error: createActions.error, isSuccess: createActions.isSuccess }, { error: updateAction.error, isSuccess: updateAction.isSuccess }, { error: deleteActions.error, isSuccess: deleteActions.isSuccess }]);

    const targetPage = id ? category?.name?.en || "None" : "New Category"

    const breadcrumbs: PropsAdminBreadcrumbs = {
        home: {
            link: `/${RootPath}categories`,
            name: "Categories"
        },
        target: targetPage
    }

    const { handleSubmit, reset, control, setValue, getFieldState, formState } = useForm<CategoryFormData>({
        defaultValues,
        resolver: yupResolver(CategorySchema),
        mode: "all",
    });

    const [iconValue, setIconValue] = useState("");

    useEffect(() => {
        if (!isLoading && category) {
            setValue("nameUA", category.name.ua);
            setValue("nameEN", category.name.en);
            setValue("key", category.type);
            setValue("icon", category.icon);
            setIconValue(category.icon);
        }
    }, [isLoading, category]);

    const navigate = useNavigateTo(`/${RootPath}categories`);

    useAction(deleteActions, navigate);

    useAction(createActions, navigate);

    const onSave = handleSubmit(data => {
        const {
            nameEN,
            nameUA,
            key,
            icon
        } = data;

        const nameUAWithReplace = nameUA.replaceAll('"', '\\"');
        const nameENWithReplace = nameEN.replaceAll('"', '\\"');

        if (isNewCategory) {
            createCategory({
                name: {
                    ua: nameUAWithReplace,
                    en: nameENWithReplace
                },
                type: key,
                icon
            });
        } else {
            updateCategory({
                id: parseInt(id || "-1"),
                name: {
                    ua: nameUAWithReplace,
                    en: nameENWithReplace
                },
                type: key,
                icon
            })
        }
    });

    const onDelete = handleSubmit(data => {
        if (!isNewCategory) {
            deleteCategory(parseInt(id || "-1"));
        }
    });

    return (
        <Category
            breadcrumbs={breadcrumbs}
            onSave={onSave}
            onDelete={onDelete}
            control={control}
            isNewCategory={isNewCategory}
            setValue={setValue}
            getFieldState={getFieldState}
            formState={formState}
            iconValue={iconValue}
        />
    );
}

export default CategoryContainer;