import { SelectItem } from "../components/inputs/BasicSelect/models/SelectItem";

export const MapType: SelectItem[] = [
    {
        name: "Фортеця Пнів",
        key: "1"
    },
    {
        name: "Тур по вулиці",
        key: "2"
    },
];