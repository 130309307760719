import { Button, Card, CardContent, Typography } from "@mui/material";
import { FC } from "react";
import { Link } from "react-router-dom";

interface Props {
    id: number;
    keyItem: string;
    index: number;
    name: string;
    handleClick: () => void;
}

const CategoryCard: FC<Props> = ({
    id,
    index,
    name,
    handleClick,
    keyItem
}) => {
    return (
        <Card
            style={{
                display: 'flex',
                flexDirection: "column",
                maxWidth: 335,
                width: "100%",
                marginRight: index + 1 % 3 === 0 ? 0 : 30,
                marginBottom: 30
            }}
        >
            <CardContent sx={{ flex: '1 0 auto' }}>
                <Link
                    to={`${id}`}
                    style={{
                        display: "block",
                        marginBottom: 5,
                        fontSize: 24,
                        color: "#000",
                        textDecoration: "underline"
                    }}
                >
                    {name}
                </Link>

                <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                    style={{
                        fontSize: 14,
                        marginBottom: 20,
                    }}
                >
                    {keyItem}
                </Typography>

                <Button 
                    onClick={handleClick}
                    color="error" 
                    sx={{ padding: 0 }}
                >
                    видалити
                </Button>
            </CardContent>
        </Card >
    );
}

export default CategoryCard;