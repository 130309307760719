import { SelectItem } from "../components/inputs/BasicSelect/models/SelectItem";

export const IconType: SelectItem[] = [
    {
        name: "Їжа",
        key: "food"
    },
    {
        name: "Будинок",
        key: "house"
    },
    {
        name: "Інформація",
        key: "info"
    },
    {
        name: "Маска",
        key: "mask"
    },
    {
        name: "Подорож",
        key: "travel"
    },
    {
        name: "Магазин",
        key: "shop"
    }
];