import { BaseSyntheticEvent, FC, SyntheticEvent, useState } from "react";
import TabsContainer from "../../components/Tabs.container";
import ContentCard from "../../../components/surfaces/ContentCard";
import TabPanel from "../../../components/navigation/TabsMenu/TabPanel";
import SlideViews from "../../../components/navigation/TabsMenu/SlideViews/SlideViews";
import MultipleSelectChip, { ChipValue } from "../../../components/inputs/MultipleSelectChip/MultipleSelectChip";
import Input from "../../../components/inputs/Input/Input";
import Row from "../../../components/layout/Row";
import Textarea from "../../../components/inputs/Textarea/Textarea";
import AdminBreadcrumbs, { PropsAdminBreadcrumbs } from "../../../components/navigation/AdminBreadcrumbs/AdminBreadcrumbs";
import { Control, FormState, UseFormGetFieldState, UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { TourFormData } from "../models/TourFormData";
import FormButtons from "../../../components/buttons/FormButtons";
import InputMiniature from "../../../components/inputs/InputMiniature/InputMiniature";
import BasicSelect from "../../../components/inputs/BasicSelect/BasicSelect";
import { MapType } from "../../../constans/MapType";

interface Props {
    breadcrumbs: PropsAdminBreadcrumbs;
    onSave: (e?: BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
    control: Control<TourFormData, any>;
    setValue: UseFormSetValue<TourFormData>;
    getFieldState: UseFormGetFieldState<TourFormData>;
    formState: FormState<TourFormData>;
    guidesChips: ChipValue[];
    contactsChips: ChipValue[];
    guidesIds: string;
    contactsIds: string; 
    mapValue: string;
}

const Tour: FC<Props> = ({
    breadcrumbs,
    onSave,
    control,
    setValue,
    getFieldState,
    formState,
    guidesChips,
    contactsChips,
    guidesIds,
    contactsIds,
    mapValue
}) => {
    const [indexTab, setIndexTab] = useState(0);

    const handleChange = (event: SyntheticEvent, newIndex: number) => {
        setIndexTab(newIndex);
    };

    return (
        <>
            <Row justifyContent="flex-start" marginBottom={20}>
                <AdminBreadcrumbs
                    home={breadcrumbs.home}
                    target={breadcrumbs.target}
                />
            </Row>

            <TabsContainer value={indexTab} handleChange={handleChange} />

            <ContentCard
                height={360}
            >
                <SlideViews
                    currentIndex={indexTab}
                >
                    <TabPanel value={indexTab} index={0}>
                        <Input
                            label="Назва"
                            marginTop={5}
                            marginBottom={15}
                            nameField="nameUA"
                            control={control}
                        />

                        <Textarea
                            label="Опис"
                            nameField="descriptionUA"
                            control={control}
                        />
                    </TabPanel>

                    <TabPanel value={indexTab} index={1}>
                        <Input
                            label="Name"
                            marginTop={5}
                            marginBottom={15}
                            nameField="nameEN"
                            control={control}
                        />

                        <Textarea
                            label="Description"
                            nameField="descriptionEN"
                            control={control}
                        />
                    </TabPanel>
                </SlideViews>
            </ContentCard>

            <ContentCard
                height={460}
            >
                <InputMiniature
                    nameField="miniature"
                    control={control}
                />
            </ContentCard>

            <ContentCard
                height={400}
            >
                <Input
                    label="Посилання на тур у Facebook"
                    marginTop={5}
                    marginBottom={15}
                    nameField="linkOfFacebook"
                    control={control}
                />

                <Row
                    marginBottom={30}
                >
                    <MultipleSelectChip
                        values={guidesIds}
                        label="Гіди"
                        chips={guidesChips}
                        marginRight={30}
                        nameField="guides"
                        setValue={setValue}
                        getFieldState={getFieldState}
                        formState={formState}
                    />

                    <MultipleSelectChip
                        values={contactsIds}
                        label="Контакти"
                        chips={contactsChips}
                        nameField="contacts"
                        setValue={setValue}
                        getFieldState={getFieldState}
                        formState={formState}
                    />
                </Row>

                <BasicSelect
                    label="Тип карти"
                    selects={MapType}
                    marginBottom={25}
                    getFieldState={getFieldState}
                    formState={formState}
                    setValue={setValue}
                    nameField="mapTemplate"
                    value={mapValue}
                />

                <FormButtons
                    onSave={onSave}
                />
            </ContentCard>
        </>
    );
}

export default Tour;