import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { User } from "../services/authApi";

type UserStore = Pick<User, "email" | "accessToken" | "expiresIn">;

interface IUserState {
    user: UserStore | null;
}

const initialState: IUserState = {
    user: null,
};

export const userSlice = createSlice({
    initialState,
    name: 'userSlice',
    reducers: {
        logout: () => initialState,
        setUser: (state, action: PayloadAction<UserStore>) => {
            const { email, accessToken, expiresIn } = action.payload;

            state.user = { email, accessToken, expiresIn };
        },
    },
});

export default userSlice.reducer;

export const { logout, setUser } = userSlice.actions;
