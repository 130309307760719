import { Button } from "@mui/material";
import Row from "../layout/Row";
import { BaseSyntheticEvent, FC } from "react";

interface Props {
    onSave: (e?: BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
    onDelete?: (e?: BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
    isNewItem?: boolean;
}

const FormButtons: FC<Props> = ({
    onSave,
    onDelete,
    isNewItem,
}) => {
    return (
        <Row
            justifyContent={!isNewItem ? "space-between" : "flex-start"}
            alignItems="center"
            width={200}
        >
            <Button
                type="button"
                onClick={onSave}
                variant="contained"
            >
                Зберегти
            </Button>

            {!isNewItem && onDelete &&
                <Button
                    type="button"
                    onClick={onDelete}
                    variant="text"
                >
                    Видалити
                </Button>
            }
        </Row>
    );
}

export default FormButtons;