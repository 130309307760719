import { Drawer } from "@mui/material";
import React, { FC } from "react";
import { MenuItem } from "./MenuItem";
import MenuList from "./MenuList";

interface Props {
    menuOpen: boolean;
    toggleDrawer: (event: React.KeyboardEvent | React.MouseEvent) => void;
    menuItems: MenuItem[];
    rootPath?: string;
}

const DrawerMenu: FC<Props> = ({
    menuOpen,
    toggleDrawer,
    menuItems,
    rootPath,
}) => {
    return (
        <Drawer
            anchor="left"
            open={menuOpen}
            onClose={toggleDrawer}
        >
            <MenuList rootPath={rootPath} toggleDrawer={toggleDrawer} menuItems={menuItems} />
        </Drawer>
    )
}

export default DrawerMenu;