import * as yup from "yup";

const TourSchema = yup.object({
    nameUA: yup.string().required("Це поле обов'язкове"),
    descriptionUA: yup.string().required("Це поле обов'язкове"),
    nameEN: yup.string().required("This field is required"),
    descriptionEN: yup.string().required("This field is required"),
    miniature: yup.string().url("Це повинно бути посилання").required("Це поле обов'язкове"),
    linkOfFacebook: yup.string().url("Це повинно бути посилання").required("Це поле обов'язкове"),
    mapTemplate: yup.string().required("Це поле обов'язкове"),
    guides: yup.string(),
    contacts: yup.string(),
}).required();

export default TourSchema;