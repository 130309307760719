import { useForm } from "react-hook-form";
import { PropsAdminBreadcrumbs } from "../../../components/navigation/AdminBreadcrumbs/AdminBreadcrumbs";
import Place from "./Place";
import { PlaceFormData } from "../models/PlaceFormData";
import { yupResolver } from '@hookform/resolvers/yup';
import PlaceSchema from "./schema/Place.schema";
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useCreatePlaceMutation, useDeletePlaceMutation, useGetPlaceQuery, useUpdatePlaceMutation } from "../../../store/services/placeApi";
import { useDeleteServiceMutation } from "../../../store/services/servicesApi";
import useActions from "../../../hooks/useActions";
import useNavigateTo from "../../../hooks/useNavigateTo";
import useAction from "../../../hooks/useAction";
import { RootPath } from "../../../constans/app";

const defaultValues: PlaceFormData = {
    nameUA: "",
    descriptionUA: "",
    nameEN: "",
    descriptionEN: "",
    miniature: "",
    gps: ""
}

interface Props {
    isNewPlace?: boolean;
}

const PlaceContainer: FC<Props> = ({
    isNewPlace = false
}) => {
    let { id } = useParams();

    const { data, isLoading, isError } = useGetPlaceQuery(Number(id));

    const place = data;

    const [createPlace, createActions] = useCreatePlaceMutation();
    const [updatePlace, updateActions] = useUpdatePlaceMutation();
    const [deletePlace, deleteActions] = useDeletePlaceMutation();

    //@ts-ignore
    useActions([{ error: createActions.error, isSuccess: createActions.isSuccess }, { error: updateActions.error, isSuccess: updateActions.isSuccess }, { error: deleteActions.error, isSuccess: deleteActions.isSuccess }]);

    const targetPage = id ? place?.name?.en || "None" : "New Place"

    const breadcrumbs: PropsAdminBreadcrumbs = {
        home: {
            link: `/${RootPath}places`,
            name: "Places"
        },
        target: targetPage
    }

    const { handleSubmit, reset, control, setValue, getValues } = useForm<PlaceFormData>({
        defaultValues,
        resolver: yupResolver(PlaceSchema),
        mode: "all",
    });

    useEffect(() => {
        if (!isLoading && place) {
            setValue("nameUA", place.name.ua);
            setValue("descriptionUA", place.description.ua);
            setValue("nameEN", place.name.en);
            setValue("descriptionEN", place.description.en);
            setValue("gps", place.coordinates);
            setValue("miniature", place.miniature);
        }
    }, [isLoading, place]);

    const navigate = useNavigateTo(`/${RootPath}places`);

    useAction(deleteActions, navigate);

    useAction(createActions, navigate);

    const onSave = handleSubmit(data => {
        const {
            nameEN,
            nameUA,
            descriptionEN,
            descriptionUA,
            gps,
            miniature
        } = data;

        const nameUAWithReplace = nameUA.replaceAll('"', '\\"');
        const nameENWithReplace = nameEN.replaceAll('"', '\\"');
        const descriptionUAWithReplace = descriptionUA.replaceAll('"', '\\"');
        const descriptionENWithReplace = descriptionEN.replaceAll('"', '\\"');

        if (isNewPlace) {
            createPlace({
                name: {
                    ua: nameUAWithReplace,
                    en: nameENWithReplace
                },
                description: {
                    ua: descriptionUAWithReplace,
                    en: descriptionENWithReplace
                },
                coordinates: gps,
                miniature
            });
        } else {
            updatePlace({
                id: parseInt(id || "-1"),
                name: {
                    ua: nameUAWithReplace,
                    en: nameENWithReplace
                },
                description: {
                    ua: descriptionUAWithReplace,
                    en: descriptionENWithReplace
                },
                coordinates: gps,
                miniature
            })
        }
    });

    const onDelete = handleSubmit(data => {
        if (!isNewPlace) {
            deletePlace(parseInt(id || "-1"));
        }
    });

    return (
        <Place
            breadcrumbs={breadcrumbs}
            control={control}
            onSave={onSave}
            onDelete={onDelete}
            isNewPlace={isNewPlace}
        />
    )
}

export default PlaceContainer;