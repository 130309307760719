import { FC } from "react";
import { Avatar, TableBody as TBody, TableCell, TableRow } from "@mui/material";
import { TableToursRow } from "./models/TableToursRow";
import { Link } from "react-router-dom";

interface Props {
    rows: TableToursRow[]
}

const TableBody: FC<Props> = ({
    rows
}) => {
    return (
        <TBody>
            {rows.map((row) => (
                <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell
                        component="td"
                        scope="row"
                    >
                        {row.id}
                    </TableCell>
                    <TableCell>
                        <Link to={`${row.id}`}>
                            <Avatar
                                alt="miniature"
                                src={row.miniature}
                                variant="rounded"
                                sx={{ width: 60, height: 60 }}
                            />
                        </Link>
                    </TableCell>
                    <TableCell style={{ textDecoration: "underline" }}>
                        <Link to={`${row.id}`}>
                            {row.name}
                        </Link>
                    </TableCell>
                </TableRow>
            ))}
        </TBody>
    );
}

export default TableBody;