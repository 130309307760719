import { FC } from "react"
import { CategoryItem } from "./models/CategoryItem"
import CategoryCard from "../../components/dataDisplay/CategoryCard/CategoryCard";
import CardsList from "../../components/layout/CardsList";
import AdminBreadcrumbs, { PropsAdminBreadcrumbs } from "../../components/navigation/AdminBreadcrumbs/AdminBreadcrumbs";
import Row from "../../components/layout/Row";
import { Button } from "@mui/material";

interface Props {
    categories: CategoryItem[];
    breadcrumbs: PropsAdminBreadcrumbs;
    handleNavigateClick: () => void;
}

const Categories: FC<Props> = ({
    categories,
    breadcrumbs,
    handleNavigateClick
}) => {
    const categoriesList = categories.map((category, index) => (
        <CategoryCard
            key={category.key}
            name={category.name}
            id={category.id}
            keyItem={category.key}
            index={index}
            handleClick={category.delete}
        />
    ));

    return (
        <>
            <Row marginBottom={20} justifyContent="space-between">
                <AdminBreadcrumbs
                    home={breadcrumbs.home}
                    target={breadcrumbs.target}
                />

                <Button variant="contained" onClick={handleNavigateClick}>
                    Створити категорію
                </Button>
            </Row>
            <CardsList>
                {categoriesList}
            </CardsList>
        </>
    )
}

export default Categories