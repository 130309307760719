import { Breadcrumbs, Typography } from "@mui/material";
import { FC } from "react";
import { Link } from "react-router-dom";

interface Home {
    link: string;
    name: string;
}

export interface PropsAdminBreadcrumbs {
    home: Home;
    target: string;
}

const AdminBreadcrumbs: FC<PropsAdminBreadcrumbs> = ({
    home,
    target
}) => {
    return (
        <Breadcrumbs aria-label="breadcrumb">
            <Link
                style={{
                    color: "rgba(0, 0, 0, 0.6)", 
                    textDecoration: "none",
                }}
                to={`${home.link}`}
            >
                {home.name}
            </Link>

            <Typography color="text.primary">{target}</Typography>
        </Breadcrumbs>
    )
}

export default AdminBreadcrumbs;