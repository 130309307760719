import Places from "./Places";
import { TablePlacesRow } from "./models/TablePlacesRow";
import { PropsAdminBreadcrumbs } from "../../components/navigation/AdminBreadcrumbs/AdminBreadcrumbs";
import { useDeletePlaceMutation, useGetPlacesQuery } from "../../store/services/placeApi";
import useActions from "../../hooks/useActions";
import useNavigateTo from "../../hooks/useNavigateTo";
import { RootPath } from "../../constans/app";

const PlacesContainer = () => {
    const { data, isLoading, isError } = useGetPlacesQuery();
    const [deletePlace, deleteActions] = useDeletePlaceMutation();

    //@ts-ignore
    useActions([{ error: deleteActions.error, isSuccess: deleteActions.isSuccess }]);

    const rows: TablePlacesRow[] = data ? data?.map((places) => ({
        id: places.id,
        name: places.name.ua,
        miniature: places.miniature,
        action: () => { deletePlace(places.id) },
    })) : [];

    const handleNavigateClick = useNavigateTo('new');

    const breadcrumbs: PropsAdminBreadcrumbs = {
        home: {
            link: `/${RootPath}`,
            name: "Home"
        },
        target: "Places"
    }

    return (
        <Places
            rows={rows}
            handleNavigateClick={handleNavigateClick}
            breadcrumbs={breadcrumbs}
        />
    );
}

export default PlacesContainer;