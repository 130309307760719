import { useEffect } from "react";

interface DeleteActions {
    isSuccess: boolean;
    isLoading: boolean;
}

const useAction = (deleteAction: DeleteActions, callback: () => void) => {
    const { isSuccess, isLoading } = deleteAction; 

    useEffect(() => {
        if (!isLoading && isSuccess) {
            callback();
        }
    }, [isLoading]);
}

export default useAction;