import { FC, PropsWithChildren } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "../store/hooks";
import { shallowEqual } from "react-redux";

interface Props {
    baseUrl: string
}

const RequireAuth: FC<PropsWithChildren & Props> = ({ 
    children ,
    baseUrl,
}) => {
    const user = useAppSelector((state) => state.userState.user, shallowEqual);

    const isAuth = user !== null;

    const location = useLocation();

    return isAuth === true ? (
        <>{children}</>
    ) : (
        <Navigate to={`/${baseUrl}login/`} replace state={{ path: location.pathname }} />
    );
}

export default RequireAuth