import { configureStore } from '@reduxjs/toolkit';
import userReducer from "./slices/auth.slice";
import appReducer from "./slices/app.slice";
import { authApi } from './services/authApi';
import { toursApi } from './services/toursApi';
import { guidesApi } from './services/guidesApi';
import { contactApi } from './services/contactsApi';
import { categoriesApi } from './services/categoriesApi';
import { pagesApi } from './services/pagesApi';
import { placesApi } from './services/placeApi';
import { servicesApi } from './services/servicesApi';

export const store = configureStore({
    reducer: {
        [authApi.reducerPath]: authApi.reducer,
        [toursApi.reducerPath]: toursApi.reducer,
        [guidesApi.reducerPath]: guidesApi.reducer,
        [contactApi.reducerPath]: contactApi.reducer,
        [categoriesApi.reducerPath]: categoriesApi.reducer,
        [pagesApi.reducerPath]: pagesApi.reducer,
        [placesApi.reducerPath]: placesApi.reducer,
        [servicesApi.reducerPath]: servicesApi.reducer,
        userState: userReducer,
        appState: appReducer,
    },
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({}).concat([
            authApi.middleware, 
            toursApi.middleware, 
            guidesApi.middleware, 
            contactApi.middleware,
            categoriesApi.middleware,
            pagesApi.middleware,
            placesApi.middleware,
            servicesApi.middleware,
        ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

