import { FC } from "react";
import TabsMenu from "../../components/navigation/TabsMenu/TabsMenu";

interface Props {
    value: number;
    handleChange: (event: React.SyntheticEvent, indexTab: number) => void;
}

const TabsContainer: FC<Props> = ({
    value,
    handleChange,
}) => {
    const tabs = ["українська", "english"];

    return (
        <TabsMenu
            value={value}
            handleChange={handleChange}
            tabs={tabs}
        />
    );
}

export default TabsContainer;