import React from "react";
import { TableCell, TableHead as THead, TableRow } from "@mui/material";

const TableHead = () => {
    return (
        <THead>
            <TableRow>
                <TableCell>
                    ID
                </TableCell>
                <TableCell>
                    Мініатюра
                </TableCell>
                <TableCell>
                    Назва
                </TableCell>
            </TableRow>
        </THead>
    );
}

export default TableHead;