import { FC } from "react";
import Contacts from "./Contacts";
import { TableContactsRow } from "./models/TableContactsRow";
import { useNavigate } from "react-router-dom";
import { PropsAdminBreadcrumbs } from "../../components/navigation/AdminBreadcrumbs/AdminBreadcrumbs";
import { useDeleteContactMutation, useGetContactsQuery } from "../../store/services/contactsApi";
import useActions from "../../hooks/useActions";
import useNavigateTo from "../../hooks/useNavigateTo";
import { RootPath } from "../../constans/app";

const ContactsContainer: FC = () => {
    const { data, isLoading, isError } = useGetContactsQuery();
    const [deleteContact, deleteActions] = useDeleteContactMutation();

    //@ts-ignore
    useActions([{ error: deleteActions.error, isSuccess: deleteActions.isSuccess }]);

    const rows: TableContactsRow[] = data ? data?.map((contact) => ({
        id: contact.id,
        name: contact.name.ua,
        phone: contact.phone,
        link: contact.link,
        action: () => { deleteContact(contact.id) },
    })) : [];

    const handleNavigateClick = useNavigateTo('new');

    const breadcrumbs: PropsAdminBreadcrumbs = {
        home: {
            link: `/${RootPath}`,
            name: "Home"
        },
        target: "Contacts"
    }

    return (
        <Contacts
            rows={rows}
            handleNavigateClick={handleNavigateClick}
            breadcrumbs={breadcrumbs}
        />
    )
}

export default ContactsContainer;