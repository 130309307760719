import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BaseUrl, Langs, ResponseError, ResponsesStatus } from './constans';
import { ChipValue } from '../../components/inputs/MultipleSelectChip/MultipleSelectChip';
import prepareHeaders from '../../utl/prepareHeaders';

export interface Guide {
    id: number;
    name: Langs;
    description: Langs;
    phone: string;
}

export type GuideId = Pick<Guide, "id">;

export type CreateGuide = Omit<Guide, "id">;

export const GuideTag = "Guide";

export const guidesApi = createApi({
    reducerPath: 'guidesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BaseUrl}/guides/`,
        prepareHeaders,
        credentials: 'include',
    }),
    tagTypes: [GuideTag],
    endpoints: (build) => ({
        getGuides: build.query<Guide[], void>({
            query: () => "",
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ id }) => ({ type: GuideTag, id } as const)),
                        { type: GuideTag, id: 'LIST' },
                    ]
                    :
                    [{ type: GuideTag, id: 'LIST' }],
            transformResponse: (response: { guides: Guide[] }) => response.guides,
        }),
        getGuide: build.query<Guide, number>({
            query: (id) => `${id}`,
            providesTags: (result, error, id) => [{ type: GuideTag, id }],
            transformResponse: (response: { guide: Guide }) => response.guide,
        }),
        getGuidesChip: build.query<ChipValue[], void>({
            query: () => `chips`,
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ id }) => ({ type: GuideTag, id } as const)),
                        { type: GuideTag, id: 'LIST' },
                    ]
                    :
                    [{ type: GuideTag, id: 'LIST' }],
            transformResponse: (response: { chips: ChipValue[] }) => response.chips,
        }),
        createGuide: build.mutation<GuideId, CreateGuide>({
            query: (body) => {
                return {
                    url: "",
                    method: "POST",
                    body,
                }
            },
            invalidatesTags: [{ type: GuideTag, id: 'LIST' }],
            transformErrorResponse: (response: ResponseError) => response,
        }),
        updateGuide: build.mutation<Guide, Guide>({
            query: (body) => {
                return {
                    url: `${body.id}`,
                    method: "PUT",
                    body,
                }
            },
            invalidatesTags: (result, error, { id }) =>
                result
                    ? [
                        { type: GuideTag, id },
                        { type: GuideTag, id: 'LIST' },
                    ]
                    :
                    [{ type: GuideTag, id: 'LIST' }],
            transformErrorResponse: (response: ResponseError) => response,
        }),
        deleteGuide: build.mutation<ResponsesStatus, number>({
            query: (id) => {
                return {
                    url: `${id}`,
                    method: "DELETE",
                }
            },
            invalidatesTags: [{ type: GuideTag, id: 'LIST' }],
            transformErrorResponse: (response: ResponseError) => response,
        })
    }),
});

export const {
    useGetGuideQuery,
    useGetGuidesQuery,
    useGetGuidesChipQuery,
    useCreateGuideMutation,
    useDeleteGuideMutation,
    useUpdateGuideMutation
} = guidesApi;