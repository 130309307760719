import { FC, useEffect } from "react";
import Contact from "./Contact"
import { PropsAdminBreadcrumbs } from "../../../components/navigation/AdminBreadcrumbs/AdminBreadcrumbs";
import { useForm } from "react-hook-form";
import { ContactFormData } from "./models/ContactFormData";
import ContactSchema from "./schema/Contact.schema";
import { yupResolver } from '@hookform/resolvers/yup';
import { useCreateContactMutation, useDeleteContactMutation, useGetContactQuery, useUpdateContactMutation } from "../../../store/services/contactsApi";
import { useNavigate, useParams } from "react-router-dom";
import useActions from "../../../hooks/useActions";
import useNavigateTo from "../../../hooks/useNavigateTo";
import useAction from "../../../hooks/useAction";
import { RootPath } from "../../../constans/app";

const defaultValues: ContactFormData = {
    nameUA: "",
    descriptionUA: "",
    nameOfLinkUA: "",
    nameEN: "",
    descriptionEN: "",
    nameOfLinkEN: "",
    phone: "",
    linkOnContact: ""
}

interface Props {
    isNewContact?: boolean;
}

const ContactContainer: FC<Props> = ({
    isNewContact
}) => {
    let { id } = useParams();

    const { data, isLoading, isError } = useGetContactQuery(Number(id));

    const contact = data;

    const [createContact, createActions] = useCreateContactMutation();
    const [updateContact, updateAction] = useUpdateContactMutation();
    const [deleteContact, deleteActions] = useDeleteContactMutation();

    //@ts-ignore
    useActions([{ error: createActions.error, isSuccess: createActions.isSuccess }, { error: updateAction.error, isSuccess: updateAction.isSuccess }, { error: deleteActions.error, isSuccess: deleteActions.isSuccess }]);

    const targetPage = id ? contact?.name?.en || "None" : "New Contact"

    const breadcrumbs: PropsAdminBreadcrumbs = {
        home: {
            link: `/${RootPath}contacts`,
            name: "Contacts"
        },
        target: targetPage
    }

    const { handleSubmit, reset, control, setValue, formState } = useForm<ContactFormData>({
        defaultValues,
        resolver: yupResolver(ContactSchema),
        mode: "all",
    });

    useEffect(() => {
        if (!isLoading && contact) {
            setValue("nameUA", contact.name.ua);
            setValue("descriptionUA", contact.description.ua);
            setValue("nameEN", contact.name.en);
            setValue("descriptionEN", contact.description.en);
            setValue("phone", contact.phone);
            setValue("linkOnContact", contact.link);
            setValue("nameOfLinkEN", contact.nameOfLink.en);
            setValue("nameOfLinkUA", contact.nameOfLink.ua);
        }
    }, [isLoading, contact]);

    const navigate = useNavigateTo(`/${RootPath}contacts`);

    useAction(deleteActions, navigate);

    useAction(createActions, navigate);

    const onSave = handleSubmit(data => {
        const {
            nameEN,
            nameUA,
            descriptionEN,
            descriptionUA,
            phone,
            linkOnContact,
            nameOfLinkEN,
            nameOfLinkUA
        } = data;

        const nameUAWithReplace = nameUA.replaceAll('"', '\\"');
        const nameENWithReplace = nameEN.replaceAll('"', '\\"');
        const descriptionUAWithReplace = descriptionUA.replaceAll('"', '\\"');
        const descriptionENWithReplace = descriptionEN.replaceAll('"', '\\"');
        const nameOfLinkENWithReplace = nameOfLinkEN.replaceAll('"', '\\"');
        const nameOfLinkUAWithReplace = nameOfLinkUA.replaceAll('"', '\\"');

        if (isNewContact) {
            createContact({
                name: {
                    ua: nameUAWithReplace,
                    en: nameENWithReplace
                },
                description: {
                    ua: descriptionUAWithReplace,
                    en: descriptionENWithReplace
                },
                nameOfLink: {
                    ua: nameOfLinkUAWithReplace,
                    en: nameOfLinkENWithReplace
                },
                phone,
                link: linkOnContact
            });
        } else {
            updateContact({
                id: parseInt(id || "-1"),
                name: {
                    ua: nameUAWithReplace,
                    en: nameENWithReplace
                },
                description: {
                    ua: descriptionUAWithReplace,
                    en: descriptionENWithReplace
                },
                nameOfLink: {
                    ua: nameOfLinkUAWithReplace,
                    en: nameOfLinkENWithReplace
                },
                phone,
                link: linkOnContact
            })
        }
    });

    const onDelete = handleSubmit(data => {
        if (!isNewContact) {
            deleteContact(parseInt(id || "-1"));
        }
    });

    return (
        <Contact
            breadcrumbs={breadcrumbs}
            isNewContact={isNewContact}
            onSave={onSave}
            onDelete={onDelete}
            control={control}
        />
    )
}

export default ContactContainer;