import { FC, useEffect, useState } from "react";
import Tour from "./Tour";
import { PropsAdminBreadcrumbs } from "../../../components/navigation/AdminBreadcrumbs/AdminBreadcrumbs";
import { TourFormData } from "../models/TourFormData";
import { yupResolver } from '@hookform/resolvers/yup';
import TourSchema from "./schema/Tour.schema";
import { useForm } from "react-hook-form";
import { Tour as ITour, useGetTourQuery, useUpdateTourMutation } from "../../../store/services/toursApi";
import { useParams } from "react-router-dom";
import { useGetGuidesChipQuery } from "../../../store/services/guidesApi";
import { useGetContactChipQuery } from "../../../store/services/contactsApi";
import useActions from "../../../hooks/useActions";
import { RootPath } from "../../../constans/app";

const defaultValues: TourFormData = {
    nameUA: "",
    descriptionUA: "",
    nameEN: "",
    descriptionEN: "",
    miniature: "",
    linkOfFacebook: "",
    guides: "",
    contacts: "",
    mapTemplate: ""
}

const TourContainer: FC = () => {
    let { id } = useParams();

    const { data, isLoading, isError } = useGetTourQuery(Number(id));

    const guidesData = useGetGuidesChipQuery();

    const guidesChips = guidesData.data || [];

    const contactsData = useGetContactChipQuery();

    const contactsChips = contactsData.data || [];

    const tour = data;

    const [update, updateActions] = useUpdateTourMutation()

    //@ts-ignore
    useActions([{ error: updateActions.error, isSuccess: updateActions.isSuccess }]);

    const breadcrumbs: PropsAdminBreadcrumbs = {
        home: {
            link: `/${RootPath}tours`,
            name: "Tours"
        },
        target: tour?.name?.en || "None"
    }

    const { handleSubmit, control, getFieldState, setValue, formState, getValues } = useForm<TourFormData>({
        defaultValues,
        resolver: yupResolver(TourSchema),
        mode: "all",
    });

    const [guidesIds, setGuidesIds] = useState("");
    const [contactsIds, setContactsIds] = useState("");
    const [mapValue, setMapValue] = useState("");

    useEffect(() => {
        if (!isLoading && tour) {
            setValue("nameUA", tour.name.ua);
            setValue("descriptionUA", tour.description.ua);
            setValue("nameEN", tour.name.en);
            setValue("descriptionEN", tour.description.en);
            setValue("miniature", tour.miniature);
            setValue("linkOfFacebook", tour.reviewLink);

            const guides = tour.guidesIds.join(",")
            setValue("guides", guides);
            setGuidesIds(guides);

            const contacts = tour.usefulContactsIds.join(",");
            setValue("contacts", contacts);
            setContactsIds(contacts);

            const mapTemplate = `${tour.mapTemplate}`
            setValue("mapTemplate", mapTemplate);
            setMapValue(mapTemplate);
        }
    }, [isLoading, tour]);

    const onSave = handleSubmit(body => {
        const {
            nameEN,
            nameUA,
            descriptionEN,
            descriptionUA,
            miniature,
            linkOfFacebook,
            guides,
            contacts,
            mapTemplate
        } = body;

        const guidesIds = guides.length > 0 ? guides.split(",").map(guide => parseInt(guide)) : [];
        const usefulContactsIds = contacts.length > 0 ? contacts.split(",").map(contact => parseInt(contact)) : []

        const tour: ITour = {
            id: parseInt(id || "-1"),
            name: {
                ua: nameUA.replaceAll('"', '\\"'),
                en: nameEN.replaceAll('"', '\\"'),
            },
            description: {
                ua: descriptionUA.replaceAll('"', '\\"'),
                en: descriptionEN.replaceAll('"', '\\"'),
            },
            miniature,
            reviewLink: linkOfFacebook,
            guidesIds,
            usefulContactsIds,
            mapTemplate: parseInt(mapTemplate),
        }

        update(tour);
    });

    return (
        <Tour
            guidesIds={guidesIds}
            contactsIds={contactsIds}
            breadcrumbs={breadcrumbs}
            control={control}
            onSave={onSave}
            setValue={setValue}
            getFieldState={getFieldState}
            formState={formState}
            guidesChips={guidesChips}
            contactsChips={contactsChips}
            mapValue={mapValue}
        />
    );
}

export default TourContainer;