import { FC, PropsWithChildren } from "react";

interface Props {
    index: number;
    currentIndex: number;
}

const SlideViewsItem: FC<PropsWithChildren<Props>> = ({
    children,
    index,
    currentIndex,
}) => {
    const root = index - currentIndex;

    return (
        <div
            style={{
                position: "absolute",
                minWidth: "100%",
                transform: `translateX(${root * 100}%)`,
                transition: `.5s transform linear`,
            }}
        >
            {children}
        </div>
    );
}


export default SlideViewsItem;