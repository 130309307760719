import { Avatar, Box, Button, Container, CssBaseline, TextField, Typography } from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { BaseSyntheticEvent, FC } from "react";
import { Control } from "react-hook-form";
import { LoginFormData } from "./models/LoginFormData";
import Input from "../../components/inputs/Input/Input";
import FormButtons from "../../components/buttons/FormButtons";

interface Props {
    onSubmit: (e?: BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
    control: Control<LoginFormData, any>;
}

const Login: FC<Props> = ({
    onSubmit,
    control
}) => {
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
            email: data.get('email'),
            password: data.get('password'),
        });
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                component="div"
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>

                <Typography component="h1" variant="h5">
                    Увійти до Адмін Панелі Pniv
                </Typography>

                <Box component="div" sx={{ mt: 1 }}>
                    <Input
                        type="email"
                        label="Пошта"
                        marginBottom={15}
                        nameField="email"
                        control={control}
                    />

                    <Input
                        type="password"
                        label="Пароль"
                        marginBottom={0}
                        nameField="password"
                        control={control}
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={onSubmit}
                    >
                        Увійти
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}

export default Login;