import { BaseSyntheticEvent, FC, SyntheticEvent, useState } from "react";
import TabsContainer from "../../components/Tabs.container";
import ContentCard from "../../../components/surfaces/ContentCard";
import SlideViews from "../../../components/navigation/TabsMenu/SlideViews/SlideViews";
import TabPanel from "../../../components/navigation/TabsMenu/TabPanel";
import Textarea from "../../../components/inputs/Textarea/Textarea";
import Input from "../../../components/inputs/Input/Input";
import Row from "../../../components/layout/Row";
import AdminBreadcrumbs, { PropsAdminBreadcrumbs } from "../../../components/navigation/AdminBreadcrumbs/AdminBreadcrumbs";
import { Control, FormState, UseFormGetFieldState, UseFormSetValue } from "react-hook-form";
import { ServiceFormData } from "../models/ServiceFormData";
import FormButtons from "../../../components/buttons/FormButtons";
import InputMiniature from "../../../components/inputs/InputMiniature/InputMiniature";
import BasicSelect from "../../../components/inputs/BasicSelect/BasicSelect";
import MultipleSelectChip, { ChipValue } from "../../../components/inputs/MultipleSelectChip/MultipleSelectChip";

interface Props {
    breadcrumbs: PropsAdminBreadcrumbs;
    onSave: (e?: BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
    onDelete?: (e?: BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
    control: Control<ServiceFormData, any>;
    isNewService?: boolean;
    setValue: UseFormSetValue<ServiceFormData>;
    getFieldState: UseFormGetFieldState<ServiceFormData>;
    formState: FormState<ServiceFormData>;
    categoriesValues: string;
    categoriesList: ChipValue[];
}

const Service: FC<Props> = ({
    breadcrumbs,
    onSave,
    onDelete,
    control,
    isNewService,
    setValue,
    getFieldState,
    formState,
    categoriesValues,
    categoriesList
}) => {
    const [indexTab, setIndexTab] = useState(0);

    const handleChange = (event: SyntheticEvent, newIndex: number) => {
        setIndexTab(newIndex);
    };

    return (
        <>
            <Row marginBottom={20} justifyContent="space-between">
                <AdminBreadcrumbs
                    home={breadcrumbs.home}
                    target={breadcrumbs.target}
                />
            </Row>

            <TabsContainer value={indexTab} handleChange={handleChange} />

            <ContentCard
                height={360}
            >
                <SlideViews
                    currentIndex={indexTab}
                >
                    <TabPanel value={indexTab} index={0}>
                        <Input
                            label="Назва"
                            marginTop={5}
                            marginBottom={15}
                            nameField="nameUA"
                            control={control}
                        />

                        <Textarea
                            label="Опис"
                            nameField="descriptionUA"
                            control={control}
                        />
                    </TabPanel>

                    <TabPanel value={indexTab} index={1}>
                        <Input
                            label="Name"
                            marginTop={5}
                            marginBottom={15}
                            nameField="nameEN"
                            control={control}
                        />

                        <Textarea
                            label="Description"
                            nameField="descriptionEN"
                            control={control}
                        />
                    </TabPanel>
                </SlideViews>
            </ContentCard>

            <ContentCard
                height={460}
            >
                <InputMiniature
                    nameField="miniature"
                    control={control}
                />
            </ContentCard>

            <ContentCard
                height={230}
            >
                <Input
                    label="GPS"
                    marginTop={5}
                    marginBottom={10}
                    nameField="gps"
                    control={control}
                />

                <MultipleSelectChip
                    values={categoriesValues}
                    label="Категорія"
                    chips={categoriesList}
                    maxWidth={475}
                    marginBottom={25}
                    getFieldState={getFieldState}
                    formState={formState}
                    setValue={setValue}
                    nameField="categories"
                />

                <FormButtons
                    onDelete={onDelete}
                    onSave={onSave}
                    isNewItem={isNewService}
                />
            </ContentCard>
        </>
    );
}

export default Service;