import * as yup from "yup";

const ContactSchema = yup.object({
    nameUA: yup.string().required("Це поле обов'язкове"),
    descriptionUA: yup.string().required("Це поле обов'язкове"),
    nameOfLinkUA: yup.string().required("Це поле обов'язкове"),
    nameEN: yup.string().required("This field is required"),
    descriptionEN: yup.string().required("This field is required"),
    nameOfLinkEN: yup.string().required("This field is required"),
    phone: yup.string(),
    linkOnContact: yup.string().url("Це повинно бути посилання")
}).required();

export default ContactSchema;