import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BaseUrl, Langs, ResponseError, ResponsesStatus } from './constans';
import { ChipValue } from '../../components/inputs/MultipleSelectChip/MultipleSelectChip';
import prepareHeaders from '../../utl/prepareHeaders';

export interface Contact {
    id: number;
    name: Langs;
    description: Langs;
    phone: string;
    nameOfLink: Langs;
    link: string;
}

export type ContactId = Pick<Contact, "id">;

export type CreateContact = Omit<Contact, "id">;

export const ContactTag = "Contact";

export const contactApi = createApi({
    reducerPath: 'contactApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BaseUrl}/usefulContacts/`,
        prepareHeaders,
        credentials: 'include',
    }),
    tagTypes: [ContactTag],
    endpoints: (build) => ({
        getContacts: build.query<Contact[], void>({
            query: () => "",
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ id }) => ({ type: ContactTag, id } as const)),
                        { type: ContactTag, id: 'LIST' },
                    ]
                    :
                    [{ type: ContactTag, id: 'LIST' }],
            transformResponse: (response: { usefulContacts: Contact[] }) => response.usefulContacts,
        }),
        getContact: build.query<Contact, number>({
            query: (id) => `${id}`,
            providesTags: (result, error, id) => [{ type: ContactTag, id }],
            transformResponse: (response: { usefulContact: Contact }) => response.usefulContact,
        }),
        getContactChip: build.query<ChipValue[], void>({
            query: () => `chips`,
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ id }) => ({ type: ContactTag, id } as const)),
                        { type: ContactTag, id: 'LIST' },
                    ]
                    :
                    [{ type: ContactTag, id: 'LIST' }],
            transformResponse: (response: { chips: ChipValue[] }) => response.chips,
        }),
        createContact: build.mutation<ContactId, CreateContact>({
            query: (body) => {
                return {
                    url: "",
                    method: "POST",
                    body,
                }
            },
            invalidatesTags: [{ type: ContactTag, id: 'LIST' }],
            transformErrorResponse: (response: ResponseError) => response,
        }),
        updateContact: build.mutation<Contact, Contact>({
            query: (body) => {
                return {
                    url: `${body.id}`,
                    method: "PUT",
                    body,
                }
            },
            invalidatesTags: (result, error, { id }) =>
                result
                    ? [
                        { type: ContactTag, id },
                        { type: ContactTag, id: 'LIST' },
                    ]
                    :
                    [{ type: ContactTag, id: 'LIST' }],
            transformErrorResponse: (response: ResponseError) => response,
        }),
        deleteContact: build.mutation<ResponsesStatus, number>({
            query: (id) => {
                return {
                    url: `${id}`,
                    method: "DELETE",
                }
            },
            invalidatesTags: [{ type: ContactTag, id: 'LIST' }],
            transformErrorResponse: (response: ResponseError) => response,
        })
    }),
});

export const {
    useGetContactsQuery,
    useGetContactQuery,
    useGetContactChipQuery,
    useCreateContactMutation,
    useDeleteContactMutation,
    useUpdateContactMutation
} = contactApi;