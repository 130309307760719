import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../store/slices/auth.slice";
import { setFeedback } from "../store/slices/app.slice";
import { FeedbackContext } from "../components/layout/MainContainer";

interface Error {
    status: number;
}

interface Action {
    error: Error | undefined,
    isSuccess: boolean;
}

const useActions = (actions: Action[]) => {
    const dispacth = useDispatch()
    const { setOpen } = useContext(FeedbackContext);

    useEffect(() => {
        actions.forEach((action) => {
            if (action.error) {
                if (action.error.status === 401) {
                    dispacth(logout());
                    dispacth(setFeedback({
                        message: "Виникла помилка з даним авторізації, введіть дані знову",
                        type: "error"
                    }));

                    if(setOpen) {
                        setOpen(true);
                    }
                } else if(action.error.status > 401) {
                    dispacth(setFeedback({
                        message: "При виконані операції виникла проблема, перевірте форму",
                        type: "error"
                    }));

                    if(setOpen) {
                        setOpen(true);
                    }
                }
            } else {
                if (action.isSuccess) {
                    dispacth(setFeedback({
                        message: "Операція пройшла успішно",
                        type: "success"
                    }));

                    if(setOpen) {
                        setOpen(true);
                    }
                }
            }
        });
    }, [...actions.map(action => action.error?.status), ...actions.map(action => action.isSuccess)]);
}

export default useActions;