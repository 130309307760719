import { FC, PropsWithChildren } from "react"

const CardsList: FC<PropsWithChildren> = ({
    children
}) => {
    return (
        <div
            style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                flexWrap: "wrap",
                width: "100%"
            }}
        >
            {children}
        </div>
    )
}

export default CardsList;