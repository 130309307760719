import { FC } from "react";
import { Button, TableBody as TBody, TableCell, TableRow } from "@mui/material";
import { Link } from "react-router-dom";
import { TableContactsRow } from "./models/TableContactsRow";

interface Props {
    rows: TableContactsRow[]
}

const TableBody: FC<Props> = ({
    rows
}) => {
    return (
        <TBody>
            {rows.map((row) => (
                <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell
                        component="td"
                        scope="row"
                    >
                        {row.id}
                    </TableCell>
                    <TableCell style={{ textDecoration: "underline" }}>
                        <Link to={`${row.id}`}>
                            {row.name}
                        </Link>
                    </TableCell>
                    <TableCell>
                            {row.phone}
                    </TableCell>
                    <TableCell>
                            {row.link}
                    </TableCell>
                    <TableCell style={{ textAlign: "end" }}>
                        <Button 
                            onClick={row.action}
                            variant="contained"
                            color="error"
                        >
                            Видалити
                        </Button>
                    </TableCell>
                </TableRow>
            ))}
        </TBody>
    );
}

export default TableBody;