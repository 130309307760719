import { Alert, AlertColor, Snackbar } from "@mui/material";
import { Dispatch, FC, SetStateAction, SyntheticEvent, useState } from "react"

interface Props {
    open: boolean;
    setOpen: (open: boolean) => void;
    message: string;
    severity?: AlertColor;
    autoHideDuration?: number;
}

const CustomSnackbar: FC<Props> = ({
    open,
    setOpen,
    message,
    severity = "success",
    autoHideDuration = 3000
}) => {
    const handleClose = (event?: SyntheticEvent | Event, reason?: string) => {
      setOpen(false);
    };

    return (
        <Snackbar 
            open={open}
            autoHideDuration={autoHideDuration} 
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
            <Alert 
                onClose={handleClose} 
                severity={severity} 
                sx={{ width: '100%' }}
            >
                {message}
            </Alert>
        </Snackbar>
    );
}

export default CustomSnackbar;