import { Typography } from "@mui/material";
import { FC } from "react";

interface Props {
    name: string;
}

const H2: FC<Props> = ({
    name
}) => {
    return (
        <Typography
            variant="h2"
            component="div"
            style={{
                marginTop: 10,
                marginBottom: 40,
                fontSize: 24,
                paddingBottom: 15,
                borderBottom: `1px solid #848785`
            }}
        > 
            {name}
        </Typography>
    );
}

export default H2;