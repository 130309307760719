import * as yup from "yup";

const GuideSchema = yup.object({
    nameUA: yup.string().required("Це поле обов'язкове"),
    descriptionUA: yup.string().required("Це поле обов'язкове"),
    nameEN: yup.string().required("This field is required"),
    descriptionEN: yup.string().required("This field is required"),
    phone: yup.string().required("This field is required"),
}).required();

export default GuideSchema;