import { useForm } from "react-hook-form";
import { PropsAdminBreadcrumbs } from "../../../components/navigation/AdminBreadcrumbs/AdminBreadcrumbs";
import Guide from "./Guide";
import { GuideFormData } from "./models/GuideFormData";
import { yupResolver } from '@hookform/resolvers/yup';
import GuideSchema from "./schema/Guide.schema";
import { FC, useEffect } from "react";
import {
    useCreateGuideMutation,
    useDeleteGuideMutation,
    useGetGuideQuery,
    useUpdateGuideMutation
} from "../../../store/services/guidesApi";
import { useNavigate, useParams } from "react-router-dom";
import useActions from "../../../hooks/useActions";
import useNavigateTo from "../../../hooks/useNavigateTo";
import useAction from "../../../hooks/useAction";
import { RootPath } from "../../../constans/app";

const defaultValues: GuideFormData = {
    nameUA: "",
    nameEN: "",
    descriptionEN: "",
    descriptionUA: "",
    phone: ""
}

interface Props {
    isNewGuide?: boolean;
}

const GuideContainer: FC<Props> = ({
    isNewGuide
}) => {
    let { id } = useParams();

    const { data, isLoading, isError } = useGetGuideQuery(Number(id));

    const guide = data;

    const [createGuide, createActions] = useCreateGuideMutation();
    const [updateGuide, updateActions] = useUpdateGuideMutation();
    const [deleteGuide, deleteActions] = useDeleteGuideMutation();

    //@ts-ignore
    useActions([{ error: createActions.error, isSuccess: createActions.isSuccess }, { error: updateActions.error, isSuccess: updateActions.isSuccess }, { error: deleteActions.error, isSuccess: deleteActions.isSuccess }]);

    const targetPage = id ? guide?.name?.en || "None" : "New Guide"

    const breadcrumbs: PropsAdminBreadcrumbs = {
        home: {
            link: `/${RootPath}guides`,
            name: "Guides"
        },
        target: targetPage
    }

    const { handleSubmit, reset, control, setValue } = useForm<GuideFormData>({
        defaultValues,
        resolver: yupResolver(GuideSchema),
        mode: "all",
    });

    useEffect(() => {
        if (!isLoading && guide) {
            setValue("nameUA", guide.name.ua);
            setValue("descriptionUA", guide.description.ua);
            setValue("nameEN", guide.name.en);
            setValue("descriptionEN", guide.description.en);
            setValue("phone", guide.phone);
        }
    }, [isLoading, guide]);

    const navigate = useNavigateTo(`/${RootPath}guides`);

    useAction(deleteActions, navigate);

    useAction(createActions, navigate);

    const onSave = handleSubmit(data => {
        const {
            nameEN,
            nameUA,
            descriptionEN,
            descriptionUA,
            phone
        } = data;

        const nameUAWithReplace = nameUA.replaceAll('"', '\\"');
        const nameENWithReplace = nameEN.replaceAll('"', '\\"');
        const descriptionUAWithReplace = descriptionUA.replaceAll('"', '\\"');
        const descriptionENWithReplace = descriptionEN.replaceAll('"', '\\"');

        if (isNewGuide) {
            createGuide({
                name: {
                    ua: nameUAWithReplace,
                    en: nameENWithReplace
                },
                description: {
                    ua: descriptionUAWithReplace,
                    en: descriptionENWithReplace
                },
                phone
            });
        } else {
            updateGuide({
                id: parseInt(id || "-1"),
                name: {
                    ua: nameUAWithReplace,
                    en: nameENWithReplace
                },
                description: {
                    ua: descriptionUAWithReplace,
                    en: descriptionENWithReplace
                },
                phone
            })
        }
    });

    const onDelete = handleSubmit(data => {
        if (!isNewGuide) {
            deleteGuide(parseInt(id || "-1"));
        }
    });

    return (
        <Guide
            onSave={onSave}
            onDelete={onDelete}
            control={control}
            isNewGuide={isNewGuide}
            breadcrumbs={breadcrumbs}
        />
    )
}

export default GuideContainer;