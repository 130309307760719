import { TableCell, TableHead as THead, TableRow } from "@mui/material";

const TableHead = () => {
    return (
        <THead>
            <TableRow>
                <TableCell>
                    ID
                </TableCell>
                <TableCell>
                    Ім'я
                </TableCell>
                <TableCell>
                    Телефон
                </TableCell>
                <TableCell></TableCell>
            </TableRow>
        </THead>
    );
}

export default TableHead;