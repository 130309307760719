import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BaseUrl, Langs, ResponseError, ResponsesStatus } from './constans';
import prepareHeaders from '../../utl/prepareHeaders';

export interface Place {
    id: number;
    name: Langs;
    description: Langs;
    coordinates: string;
    miniature: string;
}

export type PlaceId = Pick<Place, "id">;

export type CreatePlace = Omit<Place, "id">;

export const PlaceTag = "Place";

export const placesApi = createApi({
    reducerPath: 'placesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BaseUrl}/places/`,
        prepareHeaders,
        credentials: 'include',
    }),
    tagTypes: [PlaceTag],
    endpoints: (build) => ({
        getPlaces: build.query<Place[], void>({
            query: () => "",
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ id }) => ({ type: PlaceTag, id } as const)),
                        { type: PlaceTag, id: 'LIST' },
                    ]
                    :
                    [{ type: PlaceTag, id: 'LIST' }],
            transformResponse: (response: { places: Place[] }) => response.places,
        }),
        getPlace: build.query<Place, number>({
            query: (id) => `${id}`,
            providesTags: (result, error, id) => [{ type: PlaceTag, id }],
            transformResponse: (response: { place: Place }) => response.place,
        }),
        createPlace: build.mutation<PlaceId, CreatePlace>({
            query: (body) => {
                return {
                    url: "",
                    method: "POST",
                    body,
                }
            },
            invalidatesTags: [{ type: PlaceTag, id: 'LIST' }],
            transformErrorResponse: (response: ResponseError) => response,
        }),
        updatePlace: build.mutation<Place, Place>({
            query: (body) => {
                return {
                    url: `${body.id}`,
                    method: "PUT",
                    body,
                }
            },
            invalidatesTags: (result, error, { id }) =>
                result
                    ? [
                        { type: PlaceTag, id },
                        { type: PlaceTag, id: 'LIST' },
                    ]
                    :
                    [{ type: PlaceTag, id: 'LIST' }],
            transformErrorResponse: (response: ResponseError) => response,
        }),
        deletePlace: build.mutation<ResponsesStatus, number>({
            query: (id) => {
                return {
                    url: `${id}`,
                    method: "DELETE",
                }
            },
            invalidatesTags: [{ type: PlaceTag, id: 'LIST' }],
            transformErrorResponse: (response: ResponseError) => response,
        })
    }),
});

export const {
    useGetPlacesQuery,
    useGetPlaceQuery,
    useCreatePlaceMutation,
    useUpdatePlaceMutation,
    useDeletePlaceMutation,
} = placesApi;