import { FormControl, FormHelperText, TextField } from "@mui/material";
import { FC } from "react";
import { Control, Controller } from "react-hook-form";

interface Props {
    label: string;
    rows?: number;
    marginTop?: number;
    marginBottom?: number;
    nameField?: string;
    control?: Control<any, any>;
}

const Textarea: FC<Props> = ({
    label,
    rows = 9,
    marginTop,
    marginBottom,
    control,
    nameField,
}) => {
    return (
        <Controller
            render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
            }) => (
                <FormControl
                    fullWidth
                    variant="standard"
                    style={{
                        marginTop,
                        marginBottom
                    }}
                >
                    <TextField
                        error={invalid}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        inputRef={ref}
                        fullWidth
                        label={label}
                        multiline
                        rows={rows}
                        variant="outlined"
                    />

                    <FormHelperText
                        error
                        style={{
                            minHeight: 20
                        }}
                    >
                        {error?.message}
                    </FormHelperText>
                </FormControl>
            )}
            name={nameField || ""}
            control={control}
        />
    )
}

export default Textarea;